<template>
<div style="width: 100%">
    <v-container>
        <div class="desktop-header">
            <div class="top-bar">
                <v-row>
                    <v-col xs="12" sm="12" md="6">
                        <a href="mailto:info@limbeck-immo.de" title="info@limbeck-immo.de">info@limbeck-immo.de</a>
                        <a href="tel:+4905931496610" title="+49 (0) 5931 / 4966-10">+49 (0) 5931 / 4966-10</a>
                    </v-col>
                    <v-col xs="12" sm="12" md="6" class="text-right">
                        <router-link to="/privacy" title="Datenschutz">Datenschutz </router-link>
                        <router-link to="/imprint" title="Impressum">Impressum </router-link>
                        <!--<router-link to="#" title="AGB">AGB</router-link>-->
                    </v-col>
                </v-row>
            </div>
            <div class="header-bottom-bar">
                <div class="d-flex align-end justify-space-between">
                    <div class="left-menu">
                        <ul>
                            <li @click="(e)=>openOverview(e)">
                                <router-link to="/" title="Startseite">Startseite</router-link>
                            </li>
                            <li @click="(e)=>openOverview(e)">
                                <router-link to="/listing" title="Objektübersicht">Objektübersicht</router-link>
                            </li>
                        </ul>
                    </div>
                    <div @click="logoClick" class="logo-wrapper">
                        <router-link to="/">
                            <v-img :src="require('../assets/images/limbeck-logo.svg')" class="" alt="Limbeck Immobilien" title="Limbeck Immobilien" />
                        </router-link>
                    </div>
                    <div class="right-menu">
                        <ul>
                            <li><a href="/FAQ" title="FAQ">FAQ</a></li>
                            <li @click="(e)=>openOverview(e)" v-if=" this.$store.state.token">
                                <router-link to="/Myaccount" title="Einloggen">
                                    <img src="../assets/images/user-icon.png" alt="Persönliche Daten" title="Persönliche Daten" class="inactive-tabicon" />
                                </router-link>

                            </li>
                            <li @click="(e)=>openOverview(e,1)" v-else>
                                <router-link to="/login" title="Einloggen">Einloggen</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- desktop header ends -->
        <!-- tablet header starts -->
        <div class="tablet-header">
            <div class="d-flex justify-space-between align-center">
                <div class="tab-logo">
                    <router-link to="/">
                        <v-img :src="require('../assets/images/tablet-logo.png')" class="" alt="Limbeck Immobilien" title="Limbeck Immobilien" />
                    </router-link>
                </div>
                <div class="tab-navigation">
                    <ul>
                        <li>
                            <router-link to="/listing" title="Objektübersicht">Objektübersicht</router-link>
                        </li>
                        <li>
                            <router-link to="/FAQ" title="FAQ">FAQ</router-link>
                        </li>
                        <li>
                            <router-link to="/Myaccount">
                                <v-img :src="require('../assets/images/user-icon.png')" class="" alt="Limbeck Immobilien" title="Limbeck Immobilien" />
                            </router-link>
                        </li>
                        <li>
                            <button v-on:click="seen = !seen" :class="{ active: isActive }" @click="isActive = !isActive">
                                <div class="hamburger-menu">
                                    <div class="bar1"></div>
                                    <div class="bar2"></div>
                                    <div class="bar3"></div>
                                </div>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- tablet header starts -->
        <!-- mobile header starts -->
        <div class="mobile-header">
            <div class="d-flex justify-space-between align-center">
                <div class="mobile-logo">
                    <router-link to="/">
                        <v-img :src="require('../assets/images/mobile-logo.png')" class="" alt="Limbeck Immobilien" title="Limbeck Immobilien" />
                    </router-link>
                </div>
                <div class="mobile-navigation">
                    <ul>
                        <li>
                            <router-link to="/Myaccount">
                                <v-img :src="require('../assets/images/user-icon.png')" class="" alt="User" title="User" />
                            </router-link>
                        </li>
                        <li>
                            <button v-on:click="seen = !seen" :class="{ active: isActive }" @click="isActive = !isActive">
                                <div class="hamburger-menu">
                                    <div class="bar1"></div>
                                    <div class="bar2"></div>
                                    <div class="bar3"></div>
                                </div>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- mobile header ends -->
    </v-container>
    <div class="p-relative">
    <div class="navigation-div" v-if="seen">
        <v-container>
            <nav>
                <ul class="menu-items">
                    <li>
                        <router-link to="/" title="Startseite">Startseite</router-link>
                    </li>
                    <li>
                        <router-link to="/listing" title="Objektübersicht">Objektübersicht</router-link>
                    </li>
                    <li>
                        <router-link to="/FAQ" title="FAQ">FAQ</router-link>
                    </li>
                </ul>
                <ul class="inline-menu-items justify-space-between">
                    <li>
                        <router-link to="/privacy" title="Datenschutz">Datenschutz </router-link>
                    </li>
                    <li>
                        <router-link to="/imprint" title="Impressum">Impressum </router-link>
                    </li>
                    <!--<li>
                        <router-link to="#" title="AGB">AGB</router-link>
                    </li>-->
                </ul>
                <div class="bottom-contact justify-space-between align-center d-flex">
                    <a href="mailto:info@limbeck-immo.de" title="info@limbeck-immo.d">info@limbeck-immo.de</a>
                    <a href="tel:+4905931496610" title="+49 (0) 5931 / 4966-10">+49 (0) 5931 / 4966-10</a>
                </div>
            </nav>
        </v-container>
    </div>
    </div>
</div>
</template>

<script>
import Cookie from "js-cookie";

export default {
    name: "Header",
    data() {
        return {
            seen: false,
            isActive: false,
        };
    },
    mounted() {
        if (Cookie.get('token')) {
            this.$store.dispatch('setToken', Cookie.get('token'))
        }
        let childnode = document.getElementsByClassName("left-menu")[0].children[0]
        let rightChildNode = document.getElementsByClassName("right-menu")[0].children[0]
        if (this.$router.history.current.path == "/listing") {
            childnode.children[1].classList.add("active");
        } else if (this.$router.history.current.path == "/") {
            childnode.children[0].classList.add("active");
        } else if (this.$router.history.current.path == "/Myaccount") {
            rightChildNode.children[1].classList.add("active");
        } else {
            childnode.children[0].classList.remove("active");
            childnode.children[1].classList.remove("active");
        }
    },
    beforeDestroy() {},
    methods: {
        openOverview(e, notAddClass) {
            let childnode = document.getElementsByClassName("left-menu")[0].children[0]
            let rightChildNode = document.getElementsByClassName("right-menu")[0].children[0]
            childnode.children[0].classList.remove("active");
            childnode.children[1].classList.remove("active");
            rightChildNode.children[0].classList.remove("active");
            rightChildNode.children[1].classList.remove("active");
            if (e.currentTarget.children[0].attributes.href.value == this.$router.history.current.path && !notAddClass) {
                e.currentTarget.classList.add("active")
            }
        },
        logoClick() {
            let childnode = document.getElementsByClassName("left-menu")[0].children[0]
            let rightChildNode = document.getElementsByClassName("right-menu")[0].children[0]
            childnode.children[0].classList.add("active");
            childnode.children[1].classList.remove("active");
            rightChildNode.children[0].classList.remove("active");
            rightChildNode.children[1].classList.remove("active");
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";
.header-bar {
 z-index: 999;   
}
header {
    z-index: 999;
    .desktop-header {
        display: none;

        @media (min-width: 1000px) {
            display: block;
        }

        .top-bar {
            a {
                @include barlowNormal;
                font-size: 13px;
                line-height: 1.1;
                color: $tertiaryColor !important;
                text-decoration: none;
                padding: 0 17px;

                &:first-child {
                    padding-left: 0px;
                }

                &:last-child {
                    padding-right: 0px;
                }

                &:hover {
                    color: $primaryColor !important;
                }
            }
        }

        .header-bottom-bar {
            >div {
                width: 100%;
                justify-content: space-between;
            }

            .left-menu {
                width: 35%;
            }

            .right-menu {
                width: 35%;

                ul {
                    justify-content: flex-end;
                }
            }

            .logo-wrapper {
                width: 30%;
            }

            ul {
                @include listStyleNone;
                @include dFlex;

                li {
                    padding: 12px 30px;

                    @media (max-width: 1200px) {
                        padding: 12px 15px;
                    }

                    a {
                        text-decoration: none;
                        @include JudsonNormal;
                        font-size: 18px;
                        color: $tertiaryColor;
                        text-transform: capitalize;

                        &:hover {
                            color: $primaryColor !important;
                        }
                    }

                    &:first-child {
                        padding-left: 0px;
                    }

                    &:last-child {
                        padding-right: 0px;
                    }
                }

                li.active {
                    a {
                        color: $secondaryColor;
                        font-size: 18px;
                        font-weight: 700;

                        &::after {
                            content: "";
                            background: $tertiaryColor;
                            width: 33px;
                            height: 1px;
                            display: block;
                            margin: 0px auto;
                        }
                    }
                }
            }
        }
    }

    .tablet-header {
        display: none;

        @media (max-width: 1000px) {
            display: block;
        }

        @media (max-width: 767px) {
            display: none;
        }

        .tab-logo {
            width: 290px;
        }

        .tab-navigation {
            width: calc(100% - 300px);

            ul {
                @include listStyleNone;
                @include dFlex;
                justify-content: flex-end;

                li {
                    padding: 0 20px;

                    &:last-child {
                        padding-right: 0px;
                    }

                    a {
                        @include JudsonNormal;
                        font-size: 18px;
                        line-height: 1.1;
                        text-decoration: none;
                        color: $tertiaryColor;

                        &:hover {
                            color: $primaryColor;
                        }
                    }
                }
            }
        }
    }

    .mobile-header {
        display: none;
   z-index: 999;
        @media (max-width: 767px) {
            display: block;
        }

        .mobile-navigation {
            ul {
                @include listStyleNone;
                @include dFlex;

                li {
                    padding: 0 15px;

                    &:last-child {
                        padding-right: 0px;
                    }
                }
            }
        }
    }

    .navigation-div {
        position: absolute;
        width: 100%;
        background-color: #dbdbda;
        left: 0;
        right: 0;
        padding: 20px 12px 40px;
        box-shadow: 0 22px 22px -30px rgb(0 0 0 / 40%) inset;
        z-index: 999;
        a:hover {
            color: #000;
        }

        ul.menu-items {
            @include listStyleNone;

            li {
                padding: 20px 0;
                border-bottom: 1px solid #c7c6c5;

                a {
                    @include JudsonNormal;
                    font-size: 23px;
                    line-height: 1.1;
                    text-decoration: none;
                }
            }
        }

        ul.inline-menu-items {
            @include listStyleNone;
            @include dFlex;

            li {
                padding: 35px 15px;

                &:first-child {
                    padding-left: 0px;
                }

                &:last-child {
                    padding-right: 0px;
                }

                a {
                    @include barlowNormal;
                    font-size: 17px;
                    line-height: 47px;
                    text-transform: capitalize;
                    text-decoration: none;
                }
            }
        }

        .bottom-contact {
            a {
                text-decoration: none;
                @include barlowNormal;
                font-size: 14px;
                line-height: 1.1;

                @media (max-width: 350px) {
                    font-size: 13px;
                }
            }
        }
    }

    .bar1 {
        width: 30px;
        height: 3px;
        background-color: $secondaryColor;
        margin: 0px 0 5px;
        transition: 0.4s;
    }

    .bar2 {
        width: 20px;
        height: 3px;
        background-color: $secondaryColor;
        margin: 7px 0;
        transition: 0.4s;
        margin-left: auto;
    }

    .bar3 {
        width: 30px;
        height: 3px;
        background-color: $secondaryColor;
        margin: 5px 0 0;
        transition: 0.4s;
    }

    .active {
        .bar1 {
            -webkit-transform: rotate(-45deg) translate(-6px, 6px);
            transform: rotate(-45deg) translate(-6px, 6px);
        }

        .bar2 {
            opacity: 0;
        }

        .bar3 {
            -webkit-transform: rotate(45deg) translate(-8px, -9px);
            transform: rotate(45deg) translate(-8px, -9px);
        }
    }
}
.header-bar {
    z-index: 999 !important;
}
</style>
