<template>
<div>
    <v-container>
        <div class="errror-wrapper">
            <div>
                <v-img :src="require('../assets/images/hammer-image.png')" class="" alt="Limbeck Bieterportal" title="Limbeck Bieterportal" max-width="300" />
                <h1>404</h1>
                <p>Die Seite wurde nicht gefunden</p>
                <v-btn color="primary" dark class="green-btn" title="Startseite">
                    Startseite
                </v-btn>
            </div>
        </div>
    </v-container>
</div>
</template>

<script>
export default {
    name: "PageNotFound",
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.errror-wrapper {
    text-align: center;
    margin: 10% 0;
    @include flexHoriontallyCenter;
    h1 {
        @include JudsonBold;
        font-size: 50px;
        line-height: 1.1;
        margin: 22px 0 0px;
        color: $primaryColor;
    }
    p {
        @include barlowSemibold;
        font-size: 14px;
        line-height: 17px;
        color: $secondaryColor;
        margin-bottom: 40px;
    }
}
</style>
