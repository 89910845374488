import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
Vue.use(Vuetify);

new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#314C20",
        secondary: "#707173",
        gray: "#9E9D9C",
        error: "#D60000",
        red: "#D60000",
      },
    },
  },
});

export default new Vuetify({});
