<template>
<div>
    <v-container>
        <div class="login-wrapeper">
            <div class="loginwrap">
                <h4>Passwort zurücksetzen</h4>
                <v-form @submit.prevent="sumbitResetPasswordDetail()" ref="resetPassword">
                    <v-text-field label="Neues Passwort" ref="passwordEl" type="password" v-model="password" :rules="passwordRule" outlined solo></v-text-field>
                    <v-text-field type="password" label="Passwort wiederholen" v-model="confirmPassword" :rules="confirmPasswordRule" solo outlined></v-text-field>

                    <div class="login">
                        <router-link to="/login" title=" Einloggen"> Einloggen</router-link>
                    </div>

                    <div class="btn-div text-center">
                        <v-btn type="submit" color="primary" :loading="resetPasswordLoading" dark class="green-btn" title="Einloggen">
                            Passwort zurücksetzen
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </v-container>
</div>
</template>

<script>
import Cookie from "js-cookie";
import ApiBaseUrl from "../utilits/commonFunction"

export default {
    name: "ResetPassword",
    data() {
        return {
            resetPasswordLoading: false,
            passwordEl: "",
            password: "",
            confirmPassword: "",
            passwordRule: [
                v => !!v || "Passwort eingeben",
                v => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(v) || "Das Passwort muss mindestens 1 Kleinbuchstaben, 1 Großbuchstaben und eine 1 Zahl enthalten sowie mindestens 8 Zeichen lang sein."
            ],
            confirmPasswordRule: [
                v => !!v || "Passwort bestätigen",
                v => this.password == v || "Die Passwörter stimmen nicht überein."
            ]
        };
    },
    beforeCreate() {

    },
    mounted() {
        this.$refs.passwordEl.focus()
    },
    methods: {

        sumbitResetPasswordDetail() {

            if (this.$refs.resetPassword.validate()) {
                this.resetPasswordLoading = true
                let bodyParameter = {
                    email: this.$route.params.email,
                    password: this.password,
                    passwordConf: this.confirmPassword,
                    token: this.$route.params.emailToken
                }

                fetch(ApiBaseUrl.ApiBaseUrl + "confirm/password ", {
                        "method": "post",
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-token": "token-value",
                            'Access-Control-Allow-Origin': "*"
                        },
                        body: JSON.stringify(bodyParameter),
                    })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            alert("Server returned " + response.status + " : " + response.statusText);
                        }
                    })
                    .then(response => {
                        this.resetPasswordLoading = false
                        if (response.status == 200) {
                            this.$notify({
                                type: "success",
                                group: 'foo',
                                text: response.message,
                            });
                            this.password = ""
                            this.confirmPassword = ""
                            this.result = response.body;
                            this.responseAvailable = true;
                        } else {
                            this.$notify({
                                type: "error",
                                group: 'foo',
                                text: response.message,

                            });
                        }
                    })
                    .catch(err => {
                        this.resetPasswordLoading = false
                    });
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.login-wrapeper {
    max-width: 1000px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    padding: 30px;
    margin: 20px auto 10%;
    min-height: 500px;
    border-radius: 5px;
    @include flexVerticallyCenter;
    @media (max-width: 767px) {
        padding: 30px 13px;
        min-height: 450px;
    }
    .loginwrap {
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
        @media (max-width: 991px) {
            max-width: 350px;
        }
        h4 {
            @include barlowNormal;
            font-size: 13px;
            line-height: 1.1;
            color: $tertiaryColor;
            margin-bottom: 50px;
        }
        .btn-div {
            padding-top: 60px;
            @media (max-width: 767px) {
                padding-top: 50px;
            }
        }
        .forgot-password {
            a {
                @include barlowNormal;
                font-size: 13px;
                line-height: 1.1;
                color: $tertiaryColor;

                &:hover {
                    text-decoration: none;
                    color: $primaryColor;
                }
            }
        }
        .registration-password {
            a {
                @include barlowNormal;
                font-size: 13px;
                line-height: 1.1;
                color: $tertiaryColor;
                &:hover {
                    text-decoration: none;
                    color: $primaryColor;
                }
            }
        }
    }
}
</style>
