<template>
<div>
    <v-container>
        <div class="myaccount-wrapper">
            <div class="vertical-tabs">
                <v-card>
                    <v-tabs vertical>
                        <h4>Mein Profil</h4>
                        <v-tab>
                            <img src="../assets/images/usericongray.svg" alt="Persönliche Daten" title="Persönliche Daten" class="inactive-tabicon" />
                            <img src="../assets/images/user-icon.svg" alt="Persönliche Daten" title="Persönliche Daten" class="active-tabicon" />
                            <span>Persönliche Daten</span>
                        </v-tab>
                        <v-tab>
                            <img src="../assets/images/aution-hammer.svg" alt="Gebotsübersicht" title="Gebotsübersicht" class="inactive-tabicon" />
                            <img src="../assets/images/hammer-green.svg" alt="Persönliche Daten" title="Persönliche Daten" class="active-tabicon" />
                            <span>Gebotsübersicht</span>
                        </v-tab>
                        <v-tab @click="logoutAccount()">
                            <img src="../assets/images/logout-icon.svg" alt="Ausloggen" title="Ausloggen" class="inactive-tabicon" />
                            <img src="../assets/images/logoutgreen.png" alt="Ausloggen" title="Ausloggen" class="active-tabicon" />
                            <span>Ausloggen</span>
                        </v-tab>

                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <h4>Persönliche Daten</h4>
                                    <div class="form-fileds-div">
                                        <v-form @submit.prevent="SaveChangesSubmit()" ref="updateProfile">
                                            <div class="d-flex justify-space-between">
                                                <div class="half-width">
                                                    <v-select :items="items" v-model="editProfileObj.title" :rules="titleRule" label="Titel" color="red" outlined></v-select>
                                                </div>
                                                <!--<div class="half-width">
                                                    <v-text-field label="KND-NR." outlined color="red" disabled></v-text-field>
                                                </div>-->
                                            </div>
                                            <div class="d-flex justify-space-between">
                                                <div class="half-width">
                                                    <v-text-field label="Vorname*" v-model="editProfileObj.firstName" :rules="firstNameRule" color="red" outlined required></v-text-field>
                                                </div>
                                                <div class="half-width">
                                                    <v-text-field label="Nachname*" v-model="editProfileObj.lastName" :rules="lastNameRule" color="red" outlined required></v-text-field>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-space-between">
                                                <div class="half-width">
                                                    <v-menu color="red" outlined v-model="openDatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="editProfileObj.dob" :rules="dobRule" label="Geburtsdatum (DD-MM-JJJJ)" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editProfileObj.dob" @input="openDatePicker = false"></v-date-picker>
                                                    </v-menu>
                                                </div>
                                                <div class="half-width">
                                                    <v-text-field label="Ggf. Geburtsname" v-model="editProfileObj.midName" color="red" outlined></v-text-field>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-space-between firma-field">
                                                <div class="full-width">
                                                    <v-text-field label="Firma" v-model="editProfileObj.company" color="red" outlined></v-text-field>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-space-between">
                                                <div class="half-width">
                                                    <v-text-field label="Straße*" v-model="editProfileObj.street" :rules="streetRule" color="red" outlined required></v-text-field>
                                                </div>
                                                <div class="half-width">
                                                    <v-text-field label="Hausnummer*" v-model="editProfileObj.houseNo" :rules="houseNoRule" color="red" outlined></v-text-field>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-space-between">
                                                <div class="half-width">
                                                    <v-text-field label="PLZ*" v-model="editProfileObj.postCode" :rules="postCodeRule" color="red" outlined></v-text-field>
                                                </div>
                                                <div class="half-width">
                                                    <v-text-field label="Ort*" v-model="editProfileObj.location" :rules="locationRule" color="red" outlined></v-text-field>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-space-between">
                                                <div class="half-width">
                                                    <v-text-field label="Bundesland" v-model="editProfileObj.state" :rules="stateRule" color="red" outlined></v-text-field>
                                                </div>
                                                <div class="half-width">
                                                    <v-text-field label="Land*" v-model="editProfileObj.country" :rules="countryRule" color="red" outlined></v-text-field>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-space-between">
                                                <div class="half-width">
                                                    <v-text-field label="Website" v-model="editProfileObj.site" color="red" outlined></v-text-field>
                                                </div>
                                                <div class="half-width">
                                                    <v-text-field label="Telefonnummer" v-model="editProfileObj.phoneNo" :rules="phoneNoRule" color="red" outlined></v-text-field>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-space-between">
                                                <div class="half-width">
                                                    <v-text-field readonly label="E-Mail Adresse" v-model="editProfileObj.emailAdress" color="red" outlined></v-text-field>
                                                </div>
                                                <div class="half-width">
                                                    <v-text-field readonly label="E-Mail Adresse wiederholen" v-model="editProfileObj.repeatEmailAdress" color="red" outlined></v-text-field>
                                                </div>
                                            </div>
                                            <div class="tab-btn">
                                                <v-btn type="submit" color="primary" :loading="editProfileObj.saveIsLoading" class="green-btn">
                                                    Änderungen speichern
                                                </v-btn>
                                            </div>
                                        </v-form>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                                <v-card-text>
                                    <h4>Übersicht der abgegebenen Gebote</h4>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>

                                                <tr v-for="item in bidListDetail" :key="item.id">
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.saleOrLease}}</td>
                                                    <td>{{item.bidAmount}} €</td>
                                                    <td v-if="item.status ==2">
                                                        <img src="../assets/images/donetick.svg" alt="Gewonne Auktion" title="Gewonnene Auktion" />
                                                        <span class="td-span">Gewonnene Auktion</span>
                                                    </td>
                                                    <td v-else-if="item.status ==1">
                                                        <img src="../assets/images/wierd-icon.png" alt="Höchstbietender" title="Höchstbietender" />
                                                        <span class="td-span"> Höchstbietender</span>
                                                    </td>
                                                    <td v-else>
                                                        <img src="../assets/images/notdoneicon.svg" alt="Überboten" title="Überboten" />
                                                        <span class="td-span"> Überboten</span>
                                                    </td>
                                                </tr>

                                                <div v-if="!!bidListMessage">
                                                    {{bidListMessage}}
                                                </div>
                                            </tbody>

                                        </template>
                                    </v-simple-table>

                                    <div v-if="totalBidList" class="pagination-bottom">
                                        <v-pagination @input="next" v-model="page" circle :length="!!Number.isInteger(totalBidList/10) ?totalBidList/10:(totalBidList/10)+1" prev-icon="mdi-menu-left" next-icon="mdi-menu-right"></v-pagination>
                                    </div>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item> </v-tab-item>
                    </v-tabs>
                </v-card>
            </div>
        </div>
    </v-container>
</div>
</template>

<script>
import Cookie from "js-cookie";
import moment from 'moment'
import ApiBaseUrl from "../utilits/commonFunction"
import replceMultiStringWithSIngle from "../utilits/commonFunction"
export default {
    name: "Myaccount",
    data() {
        return {
            page: 1,
            items: ['Herr', 'Frau'],
            openDatePicker: false,
            editProfileObj: {
                title: "",
                firstName: "",
                lastName: "",
                dob: "",
                midName: "",
                company: "",
                street: "",
                houseNo: "",
                postCode: "",
                location: "",
                state: "",
                country: "",
                site: "",
                phoneNo: "",
                emailAdress: "",
                repeatEmailAdress: "",
                saveIsLoading: false
            },
            totalBidList: "",
            bidListDetail: [],
            bidListMessage: "",

            titleRule: [
                v => !!v || "Titel ist ein Pflichtfeld",
            ],
            firstNameRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Vorname ist ein Pflichtfeld",
            ],
            lastNameRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Nachname ist ein Pflichtfeld",
            ],
            dobRule: [
                v => !!v || "Geburtsdatum ist ein Pflichtfeld",
            ],
            streetRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Straße ist ein Pflichtfeld",
            ],
            houseNoRule: [
                v => /^[0-9\s]*$/g.test(v) || 'Hausnummer muss nummerisch sein',
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Hausnummer ist ein Pflichtfeld",
            ],
            postCodeRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "PLZ ist ein Pflichtfeld"
            ],
            locationRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Ort ist ein Pflichtfeld",
            ],
            stateRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Landkreis ist ein Pflichtfeld",
            ],
            countryRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Land ist ein Pflichtfeld",
            ],
            phoneNoRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Telefonnummer ist ein Pflichtfeld",
                v => /^[0-9\s- +]*$/g.test(v) || 'Telefonnummer muss nummerisch sein'
            ]
        };
    },
    mounted() {
        this.getProfile();
        this.getBidList();
    },
    methods: {
        next(page) {
            this.page = page
            this.getBidList()
        },
        getProfile() {
            let profileData = Cookie.get('profile_data') ? JSON.parse(Cookie.get('profile_data')) : ""

            if (!!profileData && Cookie.get('token')) {
                let bodyParameter = {
                    id: profileData.id
                }
                fetch(ApiBaseUrl.ApiBaseUrl + "my/account", {
                        "method": "post",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + Cookie.get('token')
                        },
                        body: JSON.stringify(bodyParameter),
                    })
                    .then(response => {
                        if (response.ok) {
                            return response.json()

                        }
                    })
                    .then(response => {
                        this.result = response.body;
                        this.responseAvailable = true;

                        if (response.status == 200) {
                            this.editProfileObj.title = response.data.title,
                                this.editProfileObj.firstName = response.data.firstName,
                                this.editProfileObj.lastName = response.data.lastName,
                                this.editProfileObj.dob = moment(response.data.dob).format('YYYY-MM-DD'),
                                this.editProfileObj.midName = response.data.maidenName,
                                this.editProfileObj.company = response.data.company,
                                this.editProfileObj.street = response.data.street,
                                this.editProfileObj.houseNo = response.data.number,
                                this.editProfileObj.postCode = response.data.zip,
                                this.editProfileObj.location = response.data.city,
                                this.editProfileObj.state = response.data.state,
                                this.editProfileObj.country = response.data.country,
                                this.editProfileObj.site = response.data.website,
                                this.editProfileObj.phoneNo = response.data.phone,
                                this.editProfileObj.emailAdress = response.data.email,
                                this.editProfileObj.repeatEmailAdress = response.data.email
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        },
        logoutAccount() {
            this.$store.dispatch('setToken', "")
            Cookie.remove('token')
            Cookie.remove('profile_data')
            this.$router.push('/login')
        },
        SaveChangesSubmit() {
            if (this.$refs.updateProfile.validate()) {
                let profileData = Cookie.get('profile_data') ? JSON.parse(Cookie.get('profile_data')) : ""
                let bodyParameters = {
                    id: profileData.id,
                    title: this.editProfileObj.title,
                    firstName: this.editProfileObj.firstName,
                    lastName: this.editProfileObj.lastName,
                    maidenName: this.editProfileObj.midName,
                    company: this.editProfileObj.company,
                    dateOfBirth: moment(this.editProfileObj.dob).format('MM/DD/YYYY'),
                    phone: this.editProfileObj.phoneNo,
                    website: this.editProfileObj.site,
                    street: this.editProfileObj.street,
                    number: this.editProfileObj.houseNo,
                    zip: this.editProfileObj.postCode,
                    city: this.editProfileObj.location,
                    state: this.editProfileObj.state,
                    country: this.editProfileObj.country
                }
                this.editProfileObj.saveIsLoading = true
                fetch(ApiBaseUrl.ApiBaseUrl + "account/update", {
                        "method": "post",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + Cookie.get('token')
                        },
                        body: JSON.stringify(bodyParameters),
                    })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        }
                    })
                    .then(response => {
                        this.editProfileObj.saveIsLoading = false
                        this.result = response.body;
                        this.responseAvailable = true;

                        if (response.status == 200) {
                            this.$notify({
                                type: "success",
                                group: 'foo',
                                duration: 3000,
                                text: response.message
                            });
                        } else {
                            this.$notify({
                                type: "error",
                                group: 'foo',
                                duration: 3000,
                                text: response.message
                            });
                        }
                    })
                    .catch(err => {
                        this.editProfileObj.saveIsLoading = false

                    });
            }
        },
        getBidList() {
            this.bidListDetail = []
            let profileData = Cookie.get('profile_data') ? JSON.parse(Cookie.get('profile_data')) : ""

            if (!!profileData && Cookie.get('token')) {
                let bodyParameter = {
                    user_id: profileData.id,
                    page: this.page,
                    limit: 10
                }
                fetch(ApiBaseUrl.ApiBaseUrl + "bid/overview", {
                        "method": "post",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + Cookie.get('token'),
                            'Access-Control-Allow-Origin': "*"
                        },
                        body: JSON.stringify(bodyParameter),
                    })
                    .then(response => {
                        if (response.ok) {
                            return response.json()

                        }
                    })
                    .then(response => {
                        this.result = response.body;
                        this.responseAvailable = true;
                        if (response.status == 200) {

                            response.data.map(item => {
                                this.getObjectApi(item.refObjectId, item.status, item.amount)
                            })
                            this.totalBidList = response.total_count
                            this.bidListMessage = response.data.length < 1 ? "Keine Einträge gefunden" : ""
                        }
                    })
                    .catch(err => {

                    });
            }
        },
        getObjectApi(objectId, status, bidAmount) {
            let body = {
                object_id: objectId
            }
            fetch(ApiBaseUrl.ApiBaseUrl + "bid/overview/object", {
                    "method": "post",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + Cookie.get('token'),
                        'Access-Control-Allow-Origin': "*"
                    },
                    body: JSON.stringify(body),
                })
                .then(response => {
                    if (response.ok) {
                        return response.json()

                    }
                })
                .then(response => {
                    this.result = response.body;
                    this.responseAvailable = true;
                    if (response.status == 200) {
                        let obj = {
                            ...response.data,
                            status,
                            bidAmount
                        }
                        this.bidListDetail.push(obj)
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.myaccount-wrapper {
    max-width: 1000px;
    margin: 20px auto 7%;
    .v-sheet {
        box-shadow: none !important;
    }
    .v-tab {
        padding: 0px;
        justify-content: flex-start;
        border-bottom: 1px solid $tertiaryColor;
        height: 55px !important;
        @include JudsonNormal;
        font-size: 16px;
        line-height: 1.4;
        text-transform: capitalize;
        letter-spacing: 0.4px;
        &:last-child {
            border-bottom: none;
        }
        &:before {
            display: none;
        }
        @media (max-width: 991px) {
            justify-content: center;
        }
        span {
            @media (max-width: 767px) {
                display: none;
            }
        }
        img {
            margin-right: 15px;
            @media (max-width: 767px) {
                margin-right: 0px;
            }
        }
    }
    .form-fileds-div {
        .d-flex {
            @media (max-width: 767px) {
                display: block !important;
            }
        }
        .half-width {
            width: 48%;
            @media (max-width: 767px) {
                width: 100%;
            }
        }
        .full-width {
            width: 100%;
        }
    }
    .vertical-tabs {
        padding: 70px 40px 60px 40px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        @media (max-width: 991px) {
            padding: 40px;
        }
        @media (max-width: 767px) {
            padding: 30px 12px 60px;
        }
        h4 {
            color: $tertiaryColor;
            font-size: 13px;
            @include barlowNormal;
            line-height: 1.3;
            margin-bottom: 40px;

            @media (max-width: 991px) {
                display: none;
            }
        }
        .v-slide-group__content {
            h4 {
                padding-top: 16px;
                margin-bottom: 24px;
            }
        }
        .ortsteil-field {
            justify-content: flex-end;
        }
        .tab-btn {
            margin-top: 20px;
        }
    }
    .v-tabs {
        @media (max-width: 991px) {
            display: block;
        }
        .active-tabicon {
            display: none;
        }
    }
    .v-tab--active {
        &::after {
            @media (min-width: 991px) {
                display: none;
            }
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #fff;
        }
        &::before {
            @media (min-width: 991px) {
                display: none;
            }
            content: "";
            position: absolute;
            width: 22px;
            height: 19px;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $tertiaryColor;
            display: block;
            opacity: 1 !important;
            top: auto;
            right: auto;
            background-color: transparent;
        }
        .active-tabicon {
            display: inline;
        }
        .inactive-tabicon {
            display: none;
        }
    }

    table {
        tr {
            td {
                @include barlowNormal;
                font-size: 15px !important;
                line-height: 1.4;
                color: $secondaryColor;
                padding: 0 5px !important;
                @media (max-width: 1199px) {
                    font-size: 14px !important;
                    padding: 0 5px !important;
                }
                &:first-child {
                    padding-left: 0px;
                }
                &:nth-child(2) {
                    @media (max-width: 600px) {
                        display: none;
                    }
                }
                &:nth-child(3) {
                    @media (max-width: 600px) {
                        display: none;
                    }
                }
                &:last-child {
                    padding-right: 0px;
                }
                @media (max-width: 600px) {
                    padding: 6px !important;
                }
                img {
                    margin-right: 15px;
                    @media (max-width: 1550px) {
                        margin-right: 10px;
                    }
                    @media (max-width: 600px) {
                        margin-right: 0px;
                    }
                }
                span.td-span {
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
        }
    }
    .v-tabs-bar__content {
        transform: none !important;
    }
}
.v-text-field--outlined .v-label {
    z-index: 1 !important;
}
.pagination-bottom {
    @include dFlex;
    justify-content: flex-end;
    margin: 35px 0 0;
    @media (max-width: 991px) {
        justify-content: center;
    }
}
</style>
