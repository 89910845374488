<template>
<v-app>
    <v-app-bar app color="white" class="header-bar" :style="{ position: 'inherit', 'z-index': '999' }">
        <Header />
    </v-app-bar>
    <v-main :style="{ 'padding-top': '0px', 'padding-bottom': '0px' }">
        <notifications group="foo" animation-name="v-fade-left" :duration="3000" animation-type="velocity" />
        <v-container fluid>
            <router-view></router-view>
        </v-container>
    </v-main>
    <v-footer app color="white" :style="{ position: 'inherit' }">
        <Footer />
    </v-footer>
</v-app>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";
export default {
    name: "App",

    data: () => ({
        //
    }),

    components: {
        Header,
        Footer,
    },
 
};
</script>

<style lang="scss" scoped>

</style>
