<template>
  <div class="bannner-section-slider">

    <!-- <VueSlickCarousel v-bind="settings"> -->
      <!-- slide 1--->
       <carousel :scrollPerPage="true" :loop="true" :dots="false" :per-page="1" :navigate-to="someLocalProperty" :mouse-drag="false" fade>
       <slide v-for="item in banner" :key="item.id">
      <!-- <div v-for="item in banner" :key="item"> -->
          <!-- <div
          v-if="image"
          class="banner-image-wrapper"
          :style="{ 'background-image': 'url(' + image + ')' }"
          title="Limbeck Bieterportal"
        > -->
        <div>
        <div
          v-if="image"
          class="banner-image-wrapper"
          :style="{ 'background-image': 'url(../asset/main_banner/' + item.name }"
          title="Limbeck Bieterportal"
        >
      
          <div class="banner-content">
            <h1>{{ item.subTittle }}</h1>
            <p>{{ item.heading }}</p>
          </div>
        </div>
        </div>
      <!-- </div> -->
      <!-- slide 1 end--->
      <!-- slide 2--->
      <!-- <div>
        <div
          v-if="image"
          class="banner-image-wrapper"
          :style="{ 'background-image': 'url(' + image + ')' }"
          title="Limbeck Bieterportal"
        >
          <div class="banner-content">
            <h1>{{ title }}</h1>
            <p>{{ description }}</p>
          </div>
        </div>
      </div> -->
      <!-- slide 2 ends--->
    <!-- </VueSlickCarousel> -->
     </slide>
  </carousel>
    <div class="banner-hammer-img">
      <div class="hammer-box">
        <img
          :src="require('../assets/images/hammer-image.png')"
          class=""
          alt="Limbeck Bieterportal"
          title="Limbeck Bieterportal"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "Banner",
  props: ["banner","title", "description", "image"],
  data: () => ({
    // settings: {
    //   dots: false,
    //   arrows: true,
    //   infinite: true,
    //   speed: 1000,
    //   autoplaySpeed: 9000,
    //   pauseOnFocus: false,
    //   pauseOnHover: false,
    //   autoplay: true,
    //   fade: true,
    //   slidesToShow: 1,
    //   slidesToScroll: 1
    // }
  }),
  components: {
    // VueSlickCarousel
      Carousel,
           Slide
  },
  mounted(){
  },
  methods:{
    
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/mixins.scss";
.bannner-section-slider {
  position: relative;
  @media (max-width: 767px) {
    margin-right: -30px;
  }
  .banner-hammer-img {
    position: absolute;
    top: 90px;
    left: -142px;
    @media (max-width: 1400px) {
      top: 100px;
      left: -112px;
    }
    @media (max-width: 1360px) {
      top: 129px;
      left: -72px;
    }
    @media (max-width: 1260px) {
      top: 79px;
      left: -121px;
    }
    @media (max-width: 1199px) {
      top: 53px;
      left: -70px;
    }
    @media (max-width: 991px) {
      top: 76px;
      left: -87px;
    }
    @media (max-width: 576px) {
      top: 109px;
      left: -61px;
    }
    .hammer-box {
      img {
        max-width: 480px;
        @media (max-width: 1400px) {
          max-width: 450px;
        }
        @media (max-width: 1360px) {
          max-width: 400px;
        }
        @media (max-width: 1199px) {
          max-width: 350px;
        }
        @media (max-width: 991px) {
          max-width: 290px;
        }
        @media (max-width: 576px) {
          max-width: 195px;
        }
      }
    }
  }
  .banner-image-wrapper {
    box-shadow: 0px 0px 20px rgb(0 0 0 / 30%);
    border-radius: 0px 0px 10px 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    min-height: 320px;
    @media (max-width: 1263px) {
      min-height: 260px;
    }
    @media (max-width: 1100px) {
      min-height: 220px;
    }
    @media (max-width: 991px) {
      min-height: 200px;
      padding-bottom: 5px;
    }
    @media (max-width: 767px) {
      border-radius: 0px;
      margin: 0 0px 0 0px;
      justify-content: flex-end;
    }
    @include flexHoriontallyCenter;
    align-items: flex-end;
    padding-bottom: 35px;
    // .hammer-box {
    //   position: absolute;
    //   left: -155px;
    //   max-width: 510px;
    //   bottom: -75px;
    //   @media (min-width: 1904px) {
    //     left: -125px;
    //     max-width: 480px;
    //     bottom: -65px;
    //   }
    //   @media (max-width: 1439px) {
    //     left: -121px;
    //     max-width: 450px;
    //     bottom: -50px;
    //   }
    //   @media (max-width: 1365px) {
    //     left: -70px;
    //     max-width: 360px;
    //     bottom: -40px;
    //   }
    //   @media (max-width: 1263px) {
    //     left: -113px;
    //     max-width: 380px;
    //     bottom: -51px;
    //   }
    //   @media (max-width: 1100px) {
    //     left: -65px;
    //     max-width: 300px;
    //     bottom: -34px;
    //   }
    //   @media (max-width: 991px) {
    //     left: -12px;
    //     max-width: 205px;
    //     bottom: -43px;
    //   }
    //   @media (max-width: 767px) {
    //     left: -72px;
    //     max-width: 255px;
    //     bottom: -40px;
    //   }
    //   @media (max-width: 576px) {
    //     left: -61px;
    //     max-width: 195px;
    //     bottom: -29px;
    //   }
    // }
    h1 {
      @include JudsonNormal;
      font-size: 60px;
      color: #fff;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      line-height: 1.1;
      @media (max-width: 1263px) {
        font-size: 50px;
      }
      @media (max-width: 991px) {
        font-size: 40px;
      }
      @media (max-width: 767px) {
        font-size: 35px;
      }
      @media (max-width: 576px) {
        font-size: 30px;
      }
    }
    .banner-content {
      text-align: center;
      position: relative;
      z-index: 1;
      @media (max-width: 767px) {
        text-align: left;
        width: calc(100% - 150px);
        margin-right: 24px;
      }
      @media (max-width: 576px) {
        width: calc(100% - 100px);
        margin-right: 15px;
      }
      p {
        @include barlowNormal;
        font-size: 18px;
        color: #fff;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        @media (max-width: 1263px) {
          font-size: 16px;
        }
        @media (max-width: 767px) {
          font-size: 15px;
        }
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }
    }
  }
}

</style>
