import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token:"",
    sixLatestBid:{
      latestSixBid:[]
    }
  },
  mutations: {
    SET_TOKEN(state, action) {
      state.token = action
    },
    SET_SIX_LATEST(state, action) {
      state.sixLatestBid = action
    }
  },
  actions: {
    async setToken({ commit }, payload) {
      await commit('SET_TOKEN', payload)
    },
    async setLatestBid({ commit }, payload) {
      await commit('SET_SIX_LATEST', payload)
    }
  },
  modules: {},
  getters: {
    tokenValue: state => state.token,
    getLatestBid: state=> state.sixLatestBid
  }
});

