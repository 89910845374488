<template>
<!-- class="bidbox-wrapper red-border-box" -->
<div v-bind:class="getClass(bids.startDate)">
    <div class="img-wrap">
        <img @error="onDefaultImage" class="product-img-wrap" :src="`../asset/object_image/small_image/`+bids.image" alt="{{bids.name}}" />
        <!-- <v-img 
        :src="require('../assets/images/object_image/'+bids.image)" 
        class="" alt="Grundstück (Großer Sand)"  
         /> -->
        <div class="auction-cat">
            <div class="d-flex justify-space-between">
                <span v-if="!!bids.refID">#{{bids.refID}}</span>
                <span>{{fetchCatName(bids)}}</span>
            </div>
            <div class="d-flex justify-end">
                <span>{{fetchTagName(bids)}}</span>
            </div>
        </div>
        <div class="hover-overlay">
            <div class="overlay-wrapper">
                <router-link :to="'/detail/'+bids.id">
                    <v-btn color="white" dark class="white-btn" title="Detailansicht">
                        Detailansicht
                        <v-icon small @click="next" color="primary">
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                </router-link>

                <div class="pdf-expose">
                    <a :href="`../asset/object_pdf/`+bids.pdfLink" download class="d-flex">
                        PDF-Exposé herunterladen
                        <img :src="require('../assets/images/download-cloud.svg')" class="ml-1" alt="PDF-Exposé herunterladen" title="PDF-Exposé herunterladen " max-height="25" max-width="25" />
                    </a>
                </div>
                <div class="share-through-email">
                    <a :href="`mailto:?&subject= Dieses Objekt könnte Sie interessieren&body=Guten Tag,<br/>folgendes Objekt habe ich im Bieterportal von Limbeck Immobilien gefunden: <a href='https://bieterportal.liening.dev/detail/' >https://bieterportal.liening.dev/detail/${bids.id}</a></br></br>Viele Grüße`" class="d-flex">
                        Objekt per E-Mail teilen
                        <img :src="require('../assets/images/mail-send-icon.svg')" class="ml-1" alt="Objekt per E-Mail teilen" title="Objekt per E-Mail teilen" max-height="25" max-width="25" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <h2>{{bids.name}}</h2>
    <div class="d-flex justify-space-between auctionselectwrapper">
        <div class="auctionprice">
            <v-img :src="require('../assets/images/Bid-icon.png')" class="" alt="{{bids.name}}" title="{{bids.name}}" />
            {{bids.purchaseAmount}} € <span>{{bids.saleOrLease === 'lease' ? 'Pacht' : 'Kauf'}}</span>
        </div>
        <div v-if="new Date(bids.startDate) < new Date()" class="auction-time">
            <vue-countdown-timer :start-time="new Date(bids.startDate)" :end-time="new Date(bids.endDate)" :interval="1000" :end-text="'Auktion beendet!'" :day-txt="'T'" :hour-txt="'S'" :minutes-txt="'M'" :seconds-txt="'S'">
            </vue-countdown-timer>
              <template slot="countdown" slot-scope="scope">
                <span style="color: green">{{scope.props.days}}</span><i>{{scope.props.dayTxt}}</i>
                <span>{{scope.props.hours}}</span><i>{{scope.props.hourTxt}}</i>
                <span>{{scope.props.minutes}}</span><i>{{scope.props.minutesTxt}}</i>
                <span>{{scope.props.seconds}}</span><i>{{scope.props.secondsTxt}}</i>
            </template>

            <!-- <countdown :startTime="new Date(bids.startDate).getTime() + 60000" :end-time="new Date(bids.endDate).getTime() + 60000" :speed="2000">
                <span slot="process" slot-scope="{ timeObj }">{{timeObj.d}}
                    <span>T</span> {{timeObj.h}}<span>S</span> {{timeObj.m}}
                    <span>M</span> {{timeObj.s}}<span>S</span>
                </span>
                <span slot="finish">Done!</span>
            </countdown> -->
        </div>
    </div>
    <div class="minimum-bids">
        Mindestgebotshöhe <span class="font-weight-bold">{{bids.minAmount}} €</span>
    </div>
    <div class="small-devices-btn-bar d-flex justify-space-between align-center">
        <div class="btn-div">
            <router-link :to="'/detail/'+bids.id">Detailansicht
                <v-icon small @click="next" color="primary">
                    mdi-chevron-right
                </v-icon>
            </router-link>
        </div>
        <div class="icons-div d-flex align-items-center">
            <a :href="`../asset/object_pdf/`+bids.pdfLink" download title="PDF-Exposé herunterladen">
                <v-img :src="require('../assets/images/cloud-green.svg')" class="" alt="PDF-Exposé herunterladen" title="PDF-Exposé herunterladen " max-height="25" max-width="25" />
            </a>
            <a :href="`mailto:?&subject=Folgendes Objekt könnte interessant sein!&body=Folgendes Objekt könnte interessant sein: <a href='https://bieterportal.liening.dev/detail/' >https://bieterportal.liening.dev/detail/${bids.id}</a>`" title="Objekt per E-Mail teilen">
                <v-img :src="require('../assets/images/email-green.svg')" class="" alt="Objekt per E-Mail teilen" title="Objekt per E-Mail teilen " max-height="25" max-width="25" />
            </a>
        </div>
    </div>
</div>
</template>

<script>
import * as moment from 'moment';
import Cookie from "js-cookie";

export default {
    name: "Bidbox",
    props: ['bids', 'index'],
    data: () => ({
        data: 0,
        timer: "",
        polling: "",
        countdowns: [],
        profile: Cookie.get('profile_data') ? JSON.parse(Cookie.get('profile_data')) : "",
    }),
    components: {},
    mounted() {},
    methods: {
        getClass(startDate) {
            if (!!this.profile &&  this.profile.role=="admin" && new Date(startDate) > new Date()) {
                return "bidbox-wrapper red-border-box"
            } else {
                return "bidbox-wrapper"
            }
        },
        onDefaultImage(event) {
            event.target.src = require('../assets/images/placehoderimage.png')
        },
        fetchCatName(bid) {
            if (this.$router.history.current.path == "/listing") {
                return bid['cat.name']
            } else {
                return bid['cats.name']
            }
        },
        fetchTagName(bid) {
            if (this.$router.history.current.path == "/listing") {
                return bid['tag.name']
            } else {
                return bid['tags.name']
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.bidbox-wrapper {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
    transition: 0.5s ease-in-out;

    @media (min-width: 1600px) {
        padding: 20px;
    }

    @media (max-width: 991px) {
        padding: 13px;
        margin: 14px 7px;
    }

    @media (max-width: 991px) {
        margin: 14px 11px;
    }

    img.product-img-wrap {
        width: 100%;
        height: 260px;
        object-fit: cover;
    }

    &:hover {
        .img-wrap {
            .hover-overlay {
                @include dFlex;

                @media (max-width: 1199px) {
                    display: none;
                }
            }
        }
    }

    .img-wrap {
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        line-height: 0px;

        .hover-overlay {
            position: absolute;
            top: 0;
            background: rgba(0, 0, 0, 0.7);
            width: 100%;
            bottom: 0px;
            transition: 0.5s ease-in-out;
            align-items: flex-end;
            display: none;
            line-height: 1.5;

            .overlay-wrapper {
                width: 100%;
                padding: 100px 20px 10px;
                height: 100%;
                text-align: center;

                .pdf-expose {
                    @include flexVerticallyCenter;
                    @include JudsonNormal;
                    font-size: 18px;
                    color: #fff;
                    width: 100%;
                    justify-content: center;
                    padding: 20px 0px 13px;
                    gap: 12px;

                    a {
                        color: #fff;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    @media (max-width: 1366px) {
                        padding: 18px 0px 13px;
                    }

                    @media (max-width: 1199px) {
                        padding: 12px 0px;
                    }
                }

                .share-through-email {
                    @include flexVerticallyCenter;
                    @include JudsonNormal;
                    font-size: 18px;
                    color: #fff;
                    justify-content: center;
                    gap: 12px;
                    padding-bottom: 0px;

                    a {
                        color: #fff;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    @media (max-width: 1440px) {
                        padding-bottom: 0px;
                    }
                }
            }
        }

        .auction-cat {
            position: absolute;
            top: 0;
            width: 100%;
            padding: 6px 10px 10px;

            .d-flex {
                flex-wrap: wrap;
            }

            span {
                @include barlowSemibold;
                font-size: 14px;
                line-height: 1.4;
                text-align: center;
                color: #ffffff;
                text-decoration: none;
                background-color: $tertiaryColor;
                display: inline-block;
                padding: 8px 10px;
                border-radius: 5px;
                margin: 4px 0;

                @media (max-width: 1263px) {
                    font-size: 13px;
                    padding: 5px 7px;
                }

                @media (max-width: 1100px) {
                    font-size: 11px;
                    padding: 3px 5px;
                }
            }
        }
    }

    h2 {
        @include JudsonBold;
        font-size: 26px;
        line-height: 1.1;
  
        color: $primaryColor;
        margin: 20px 0 25px;

        @media (max-width: 1263px) {
            font-size: 23px;
        }

        @media (max-width: 991px) {
            font-size: 20px;
            margin: 12px 0 17px;
        }

        a {
            text-decoration: none;
        }
    }

    .auctionselectwrapper {
        flex-wrap: wrap;
        gap: 7px;

        .auctionprice {
            @include flexVerticallyCenter;
            @include barlowBold;
            font-size: 20px;
            line-height: 1.1;
            color: $primaryColor;

            @media (max-width: 1904px) {
                font-size: 19px;
            }

            @media (max-width: 1500px) {
                font-size: 18px;
            }

            @media (max-width: 991px) {
                font-size: 16px;
            }

            span {
                @include barlowSemibold;
                font-size: 14px;
                line-height: 1.1;
                color: $secondaryColor;
                text-transform: capitalize;
                padding-left: 3px;

                @media (max-width: 991px) {
                    font-size: 13px;
                }
            }

            .v-image {
                margin-right: 15px;

                @media (max-width: 1500px) {
                    margin-right: 10px;
                }
            }
        }
    }

    .auction-time {
        @include barlowBold;
        font-size: 20px;
        line-height: 1.1;
        color: #d60000;
        letter-spacing: 0px;
        i {
            font-style: normal !important;
            font-size: 12px !important;
        
        }
        @media (max-width: 1904px) {
            font-size: 19px;
        }

        @media (max-width: 1500px) {
            font-size: 18px;
        }

        @media (max-width: 991px) {
            font-size: 16px;
        }

        span {
            font-size: 16px;
            padding-right: 2px;
        }
    }

    .minimum-bids {
        @include barlowNormal;
        font-size: 16px;
        line-height: 1.1;
        color: $secondaryColor;
        padding-top: 15px;

        @media (max-width: 1500px) {
            font-size: 15px;
        }

        @media (max-width: 991px) {
            padding-top: 16px;
            padding-bottom: 8px;
        }
    }

    .small-devices-btn-bar {
        border-top: 1px solid #9e9d9c;
        margin-top: 20px;
        padding-top: 7px;

        @media (min-width: 1200px) {
            display: none !important;
        }

        a {
            @include JudsonBold;
            font-size: 17px;
            line-height: 20px;
            color: $primaryColor;
            text-decoration: none;

            i {
                padding-left: 5px;
            }
        }

        .icons-div {
            a {
                padding: 0 10px;

                &:last-child {
                    padding-right: 0px;
                }
            }
        }
    }
}

.red-border-box {
    border: 1px solid red;
}
</style>
