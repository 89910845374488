<template>
<div class="detail-page">
    <!-- top image div -->
    <div class="detail-wrapper">
        <v-container>
            <v-row class="align-end">
                <v-col xs="12" sm="12" md="8">
                    <div class="img-wrap">
                        <img @error="onDefaultImage" class="" :src="`../asset/object_image/large_image/`+objectDetail.image" alt="Detailbild" />
                        <!-- <v-img :src="require('../asset/api-image/object_image/'+objectDetail.image)" class="" alt="Grundstück (Großer Sand)" title="Grundstück (Großer Sand)" /> -->
                        <!-- <v-img :src="require('../assets/images/detail-img.png')" class="" alt="Grundstück (Großer Sand)" title="Grundstück (Großer Sand)" /> -->
                    </div>
                </v-col>
                <v-col xs="12" sm="12" md="4">
                    <div class="summary-wrapper">
                        <div class="summary-title">
                            <h2>{{objectDetail.name}}</h2>
                            <p>#{{objectDetail.refID}}</p>
                        </div>
                        <div class="price-summary">
                            <h4>{{objectDetail.purchaseAmount}} € <span>{{objectDetail.saleOrLease}}</span></h4>
                            <h5>
                                Mindestgebotshöhe
                                <span class="font-weight-bold">{{objectDetail.minAmount}} €</span>
                            </h5>
                            <h6>
                                <div v-if="new Date(objectDetail.startDate) < new Date()">
                                    <vue-countdown-timer :start-time="new Date(objectDetail.startDate)" :end-time="new Date(objectDetail.endDate)" :interval="1000" :end-text="'Auktion beendet!'" :day-txt="'T'" :hour-txt="'S'" :minutes-txt="'M'" :seconds-txt="'S'">
                                    </vue-countdown-timer>
                                    <template slot="countdown" slot-scope="scope">
                                        <span>{{scope.props.days}}
                                            <span class="time-unit-text">T</span>{{scope.props.hours}}<span class="time-unit-text">S</span> {{scope.props.minutes}}
                                            <span class="time-unit-text">M</span>{{scope.props.seconds}}<span class="time-unit-text">S</span>
                                        </span>
                                    </template>
                                </div>
                                <!-- {{Days}}<span>T</span> {{Hours}}<span>S</span> {{Minutes}}<span>M</span> {{Seconds}}<span>S</span> -->
                            </h6>
                            <v-form @submit.prevent="BidOnProject" ref="bidAmount">
                                <v-text-field ref="bidTextField" v-model="bidAmountField" :rules="bidAmountRule()" :label="objectDetail.minAmount" outlined solo></v-text-field>
                            </v-form>
                            <v-btn :loading="bidNowLoading" color="red" @click="BidOnProject()" dark class="red-btn" title="Jetzt bieten">
                                Jetzt bieten
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <!-- top image div ends -->
    <!-- description div -->
    <div class="detail-wrap">
        <v-container>
            <v-row>
                <v-col xs="12" sm="12" md="12">
                    <div class="content-detail-wrapper">
                        <h5>Weitere Informationen</h5>
                        <ul>

                            <li v-for="item in attributeDetails" :key="item.id">
                                <h3>{{item.name}}</h3>
                                <p>{{item.value}}</p>
                            </li>
                        </ul>
                        <div class="detailed-description">
                            <ul>
                                <li>
                                    <h3>Informationen</h3>
                                    <p>
                                        Alle Anfragen bzgl. Objektdaten, Besichtigungstermine etc.
                                        sind direkt an unser Büro zu richten. Dieses Angebot ist freibleibend und unverbindlich.
                                        Das Exposé wurde entsprechend den Angaben des Verkäufers erstellt.
                                        Irrtum und Zwischenverkauf sind vorbehalten. Diese Unterlagen sind nur für Sie persönlich bestimmt.
                                        Hinweise zum Gebotsverfahren
                                        Mit der Abgabe eines Gebotes werden Sie in unser Gebotsverfahren aufgenommen.
                                        Werden höhere Kaufpreisangebote durch Dritte abgegeben,
                                        werden Sie automatisch benachrichtigt.
                                        Für die Gebotsabgabe ist das Bieterportal zu nutzen.
                                        Ihre Gebotsdaten werden selbstverständlich streng vertraulich behandelt.
                                    </p>
                                </li>
                                <li>
                                    <h3>Hinweise zum Gebotsverfahren</h3>
                                    <p>
                                        Mit der Abgabe eines schriftlichen Angebotes per Telefax,
                                        E-Mail oder postalisch werden Sie in unser Gebotsverfahren
                                        aufgenommen. Werden höhere Kaufpreisangebote durch Dritte
                                        abgegeben, werden Sie durch unser Büro dann automatisch
                                        benachrichtigt. Für die Gebotsabgabe ist bitte dieses
                                        vorgegebene Formular zu nutzen. Ihre Gebotsdaten werden
                                        selbstverständlich streng vertraulich behandelt.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <!-- top image div ends -->
</div>
</template>

<script>
import Cookie from "js-cookie";
import ApiBaseUrl from "../utilits/commonFunction"

export default {
    name: "Detail",
    components: {},
    data: () => ({
        objectDetail: "",
        attributeDetails: [],
        bidAmountField: "",
        bidAmountRule: () => [
            v => /^[0-9\s]*$/g.test(v) || 'Das Gebot muss nummerisch sein.',
        ],
        deadline: "",
        Days: "",
        Hours: "",
        Minutes: "",
        Seconds: "",
        intervalid1: '',
        profileData: Cookie.get('profile_data') ? JSON.parse(Cookie.get('profile_data')) : "",
        bidNowLoading: false,

    }),
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.getAttributeDetail();

    },
    beforeDestroy() {

    },
    methods: {
        onDefaultImage(event) {
            event.target.src = require('../assets/images/placehoderimage.png')
        },
        startTimer(endtime, startDate) {

            if (!!endtime, !!startDate) {
                this.intervalid1 = setInterval(() => {
                    const t = this.getTimeRemaining(endtime, startDate);
                    this.Days = t.days
                    this.Hours = t.hours
                    this.Minutes = t.minutes
                    this.Seconds = t.seconds;
                    if (t.total <= 0) {
                        clearInterval(this.intervalid1);
                    }
                }, 1000);
            }
        },
        getTimeRemaining(endtime, startDate) {

            const total = Date.parse(endtime) - Date.parse(new Date());
            const seconds = Math.floor((total / 1000) % 60);
            const minutes = Math.floor((total / 1000 / 60) % 60);
            const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
            const days = Math.floor(total / (1000 * 60 * 60 * 24));

            return {
                total,
                days,
                hours,
                minutes,
                seconds
            };
        },
        getAttributeDetail() {
            let bodyParameter = {
                id: this.$route.params.id
            }
            fetch(ApiBaseUrl.ApiBaseUrl + 'object/user/details ', {
                    "method": "post",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + Cookie.get('token')
                    },
                    body: JSON.stringify(bodyParameter),
                })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                })
                .then(response => {
                    if (response.status == 200) {
                        this.objectDetail = response.data
                        this.attributeDetails = response.data.result
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        BidOnProject() {
            if (!!this.$store.state.token) {

                if (this.$refs.bidAmount.validate()) {
                    this.bidNowLoading = true
                    let bodys = {
                        object_id: this.$route.params.id,
                        purchase_amt: this.bidAmountField,
                        user_id: this.profileData.id

                    }
                    fetch(ApiBaseUrl.ApiBaseUrl + 'bidAdd', {
                            "method": "post",
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization": "Bearer " + Cookie.get('token'),
                                'Access-Control-Allow-Origin': "*"
                            },
                            body: JSON.stringify(bodys),
                        })
                        .then(response => {
                            if (response.ok) {
                                return response.json()
                            }
                        })
                        .then(response => {
                            this.bidNowLoading = false

                            if (response.status == 200) {
                                // this.$refs.bidTextField.validate=true
                                this.objectDetail.endDate = ""
                                clearInterval(this.intervalid1)
                                this.$notify({
                                    type: "success",
                                    group: 'foo',
                                    duration: 3000,
                                    text: response.message
                                });
                                this.bidAmountField = ""
                                this.objectDetail.purchaseAmount = !!response.purchaseAmt ? response.purchaseAmt : this.objectDetail.purchaseAmount
                                this.objectDetail.endDate = !!response.endDate ? response.endDate : this.objectDetail.endDate
                                this.objectDetail.minAmount = response.minimumAmount

                            } else {
                                // this.bidNowValidationMessage=response.message
                                this.$notify({
                                    type: "error",
                                    group: 'foo',
                                    duration: 3000,
                                    text: response.message
                                });
                            }
                        })
                        .catch(error => {
                            this.bidNowLoading = false

                        })
                }
            } else {
                this.$router.push('/login')
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.detail-wrapper {
    .img-wrap {
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .summary-wrapper {
        .summary-title {
            padding-bottom: 15px;

            h2 {
                @include JudsonBold;
                font-size: 26px;
                line-height: 1.4;
                color: $primaryColor;
            }

            p {
                @include barlowSemibold;
                font-size: 14px;
                line-height: 17px;
                color: $secondaryColor;
            }
        }

        .price-summary {
            h4 {
                @include barlowBold;
                font-size: 30px;
                line-height: 36px;
                margin-bottom: 10px;
                color: $primaryColor;

                span {
                    @include barlowSemibold;
                    font-size: 14px;
                    line-height: 1.1;
                    color: $secondaryColor;
                    text-transform: capitalize;
                    padding-left: 3px;
                }
            }

            h5 {
                @include barlowNormal;
                font-size: 16px;
                line-height: 1.1;
                color: $secondaryColor;
            }

            h6 {
                @include barlowBold;
                font-size: 20px;
                line-height: 1.1;
                color: #d60000;
                letter-spacing: 0px;
                margin: 15px 0 20px; 
               i {    
                    font-style: normal; 
                    font-size: 11px;
                }
                @media (max-width: 767px) {
                    margin: 25px 0 30px;
                }

                span {
                    font-size: 10px;
                    padding-right: 2px;
                }
            }
        }
    }
}

.content-detail-wrapper {
    margin: 5% 0;

    h5 {
        @include barlowSemibold;
        font-size: 14px;
        line-height: 17px;
        border-bottom: 1px solid $tertiaryColor;
        padding-bottom: 12px;
        margin-bottom: 20px;
        color: $secondaryColor;
    }

    ul {
        @include listStyleNone;
        @include dFlex;
        flex-wrap: wrap;

        li {
            width: 33.3%;
            padding: 20px 40px 20px 0px;

            @media (max-width: 1199px) {
                padding: 20px 15px 20px 0px;
            }

            @media (max-width: 767px) {
                width: 100%;
                padding: 20px 15px 0 0px;
            }

            h3 {
                @include JudsonBold;
                font-size: 18px;
                line-height: 1.1;
                margin-bottom: 20px;
                color: $primaryColor;
            }

            p {
                font-family: Barlow;
                font-style: normal;
                font-weight: 500;
                @include barlowSemibold;
                font-size: 14px;
                line-height: 17px;
                color: $secondaryColor;
            }
        }
    }

    .detailed-description {
        border-top: 1px solid $tertiaryColor;
        padding-top: 25px;
        margin-top: 30px;

        ul {
            li {
                width: 50%;
                padding-right: 0;

                &:nth-child(2n + 1) {
                    padding-right: 30px;
                }

                @media (max-width: 959px) {
                    width: 100%;
                }
            }
        }
    }
}
</style>
