<template>
<div>
    <carousel :scrollPerPage="true" :loop="true" :per-page="2.5" :navigate-to="someLocalProperty" :mouse-drag="true">
        <slide v-for="(item, index) in latestBids" :key="index">
            <Bidbox :bids="item" :index="index" />
        </slide>
    </carousel>
    <!-- <VueSlickCarousel v-bind="settings">
        <div v-for="item in latestBids" :key="item.id">
            <Bidbox :bids="item"/>
        </div>
    </VueSlickCarousel> -->
</div>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Bidbox from "../components/bid-box";
import {
    Carousel,
    Slide
} from 'vue-carousel';
export default {
    name: "FiveMinBidsslider",
    props: ['latestBids'],
    data: () => ({
        settings: {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 1000,
            autoplaySpeed: 9000,
            pauseOnFocus: false,
            pauseOnHover: false,
            centerMode: true,
            autoplay: true,
            centerPadding: "0",
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                    },
                },
            ],
        },
    }),
    components: {
        // VueSlickCarousel,
        Bidbox,
        Carousel,
        Slide
    },
};
</script>
