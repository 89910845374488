
const ApiBaseUrl = "https://api.bieterportal.liening.dev/"
// https://api.bieterportal.liening.dev/

const onDefaultImage =(event)=>{
event.target.src = require('../assets/images/download-cloud.svg')
}
const replceMultiStringWithSIngle = (string) => {
    if (!!string) {
        string = string.trim().replace(/\s\s+/g, ' ');
    }
    return string
}

const timeDiffCalc =(dateFuture)=> {
    let diffInMilliSeconds = Math.abs(dateFuture - new Date()) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    console.log('calculated days', days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    console.log('calculated hours', hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    console.log('minutes', minutes);

    // calculate seconds
    const second = Math.floor(diffInMilliSeconds) % 60;
    // diffInMilliSeconds -= minutes * 60;
    console.log('second', second);

    let difference = '';
    if (days > 0) {
        difference += (days === 1) ? `${days} day, ` : `${days} days, `;
    }
    difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;

    difference += (minutes === 0 || hours === 1) ? `${minutes} minutes, ` : `${minutes} minutes, `;

    difference += (second === 0 || minutes === 1) ? `${second} second` : `${second} second`;

    return difference;
}
export default {timeDiffCalc,ApiBaseUrl ,replceMultiStringWithSIngle,onDefaultImage}