<template>
<div>
    <v-container>
        <div class="login-wrapeper">
            <div class="loginwrap">
                <h4>Passwort vergessen</h4>
                <h5>
                    Bestätigen Sie Ihre E-Mail-Adresse und wir senden die Anweisungen
                </h5>
                <v-form @submit.prevent="submitForgotPassword" ref="forgotPassword">
                    <v-text-field label="E-Mail-Adresse" v-model="email" :rules="emailRule" outlined solo></v-text-field>
                </v-form>
                <div class="btn-div text-center">
                    <v-btn color="primary" dark class="green-btn" @click="submitForgotPassword()" title="Einloggen">
                        Passwort zurücksetzen
                    </v-btn>
                </div>
            </div>
        </div>
    </v-container>
</div>
</template>

<script>
import ApiBaseUrl from "../utilits/commonFunction"
import replceMultiStringWithSIngle from "../utilits/commonFunction"
export default {
    name: "ForgotPassword",
    data() {
        return {
            email: "",
            emailRule: [
                (v) => !!v || "Bitte geben Sie Ihre E-Mail-Adresse ein",
                (v) => /.+@.+/.test(v) || "Die E-Mail-Adresse ist ungültig",
            ],
        };
    },
    methods: {
        submitForgotPassword() {
            if (this.$refs.forgotPassword.validate()) {
                let bodyParameter={
                    email:replceMultiStringWithSIngle.replceMultiStringWithSIngle(this.email),  
                }
               
                fetch(ApiBaseUrl.ApiBaseUrl + "forgot/password", {
                        "method": "post",
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-token": "token-value",
                            'Access-Control-Allow-Origin': "*"
                        },
                        body: JSON.stringify(bodyParameter),
                    })

                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            alert("Server returned " + response.status + " : " + response.statusText);
                        }
                    })
                    .then(response => {
                        this.resetPasswordLoading = false
                        if (response.status == 200) {
                            this.$notify({
                                type: "success",
                                group: 'foo',
                                text: response.message,

                            });
                            this.result = response.body;
                            this.responseAvailable = true;
                            this.email=""
                        } else {
                            this.$notify({
                                type: "error",
                                group: 'foo',
                                text: response.message,

                            });
                        }
                    })
                    .catch(err => {
                        this.resetPasswordLoading = false
                    });
            }
            
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.login-wrapeper {
    max-width: 1000px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    padding: 30px;
    margin: 20px auto 10%;
    min-height: 500px;
    border-radius: 5px;
    @include flexVerticallyCenter;
    @media (max-width: 767px) {
        padding: 30px 13px;
        min-height: 450px;
    }
    .loginwrap {
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
        @media (max-width: 991px) {
            max-width: 350px;
        }
        h4 {
            @include barlowNormal;
            font-size: 18px;
            line-height: 1.1;
            color: $tertiaryColor;
            margin-bottom: 10px;
        }
        h5 {
            @include barlowNormal;
            font-size: 13px;
            line-height: 1.1;
            color: $tertiaryColor;
            margin-bottom: 40px;
        }
        .btn-div {
            padding-top: 60px;

            @media (max-width: 767px) {
                padding-top: 50px;
            }
        }
        .forgot-password {
            a {
                @include barlowNormal;
                font-size: 13px;
                line-height: 1.1;
                color: $tertiaryColor;
                &:hover {
                    text-decoration: none;
                    color: $primaryColor;
                }
            }
        }
    }
}
</style>
