<template>
<div>
    <v-container>
        <div class="login-wrapeper">
            <div class="loginwrap">
                <h4>Login</h4>
                <v-form @submit.prevent="sumbitLoginDetail()" ref="login">
                    <v-text-field ref="emailEl" label="E-Mail-Adresse" v-model="email" :rules="emailRule" outlined solo>
                    </v-text-field>
                    <v-text-field label="Password" type="password" v-model="password" :rules="passwordRule" outlined solo></v-text-field>

                    <div class="forgot-password">
                        <router-link to="/forgot-password" title="Passwort vergessen?">Passwort vergessen?</router-link>
                    </div>
                    <div class="registration-password">
                        <router-link to="/registration" title="Registrierung">Registrierung</router-link>
                    </div>
                    <div class="btn-div text-center">
                        <v-btn type="submit" color="primary" :loading="this.LoginApiData.loginApiLoading" dark class="green-btn" title="Einloggen">
                            Einloggen
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </v-container>
</div>
</template>

<script>
import Cookie from "js-cookie";
import ApiBaseUrl from "../utilits/commonFunction"
import replceMultiStringWithSIngle from "../utilits/commonFunction"

export default {
    name: "Login",
    data() {
        return {
            emailEl: "",
            email: "",
            password: "",
            loginDataObj: {
                email: "",
                password: ""
            },
            emailRule: [
                v => !!v || "Geben Sie bitte Ihre E-Mail-Adresse ein",
                v => /.+@.+/.test(v) || 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
            ],
            passwordRule: [
                v => !!v || "Bitte geben Sie Ihr Passwort ein"
            ],
            LoginApiData: {
                loginApiLoading: false
            }
        };
    },
    beforeCreate() {

    },
    mounted() {
        this.$refs.emailEl.focus()
        this.demoVariable()
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    methods: {
        demoVariable() {

        },
        sumbitLoginDetail() {

            if (this.$refs.login.validate()) {
                this.LoginApiData.loginApiLoading = true
                this.loginDataObj.email = replceMultiStringWithSIngle.replceMultiStringWithSIngle(this.email);
                this.loginDataObj.password = this.password;
                fetch(ApiBaseUrl.ApiBaseUrl + "login", {
                        "method": "post",
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-token": "token-value",
                            'Access-Control-Allow-Origin': "*"
                        },
                        body: JSON.stringify(this.loginDataObj),
                    })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            alert("Server returned " + response.status + " : " + response.statusText);
                        }
                    })
                    .then(response => {
                        this.LoginApiData.loginApiLoading = false
                        if (response.status == 200) {
                            this.$notify({
                                type: "success",
                                group: 'foo',
                                text: 'Anmeldung erfolgreich',

                            });
                            this.result = response.body;
                            this.responseAvailable = true;
                            if (this.prevRoute.name &&
                                this.prevRoute.name != "404" &&
                                this.prevRoute.name != "Registration" && this.prevRoute.name != "ForgotPassword" &&
                                this.prevRoute.name != "ResetPassword") {
                                this.$router.push(this.prevRoute.path)

                            } else {
                                this.$router.push('/Myaccount');
                            }
                            this.$store.dispatch('setToken', response.token)
                            Cookie.set("token", response.token, {
                                expires: 1
                            });
                            Cookie.set("profile_data", JSON.stringify(response.data), {
                                expires: 1
                            });
                            let childnode = document.getElementsByClassName("left-menu")[0].children[0]
                            let rightChildNode = document.getElementsByClassName("right-menu")[0].children[0]
                            if (this.$router.history.current.path == "/listing") {
                                childnode.children[1].classList.add("active");
                            } else if (this.$router.history.current.path == "/") {
                                childnode.children[0].classList.add("active");
                            } else if (this.$router.history.current.path == "/Myaccount") {
                                rightChildNode.children[1].classList.add("active");
                            } else {
                                childnode.children[0].classList.remove("active");
                                childnode.children[1].classList.remove("active");
                            }
                        } else {
                            this.$notify({
                                type: "error",
                                group: 'foo',
                                text: response.message,
                            });
                        }
                    })
                    .catch(err => {
                        this.LoginApiData.loginApiLoading = false
                    });
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.login-wrapeper {
    max-width: 1000px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    padding: 30px;
    margin: 20px auto 10%;
    min-height: 500px;
    border-radius: 5px;
    @include flexVerticallyCenter;

    @media (max-width: 767px) {
        padding: 30px 13px;
        min-height: 450px;
    }

    .loginwrap {
        max-width: 500px;
        margin: 0 auto;
        width: 100%;

        @media (max-width: 991px) {
            max-width: 350px;
        }

        h4 {
            @include barlowNormal;
            font-size: 13px;
            line-height: 1.1;
            color: $tertiaryColor;
            margin-bottom: 50px;
        }

        .btn-div {
            padding-top: 60px;

            @media (max-width: 767px) {
                padding-top: 50px;
            }
        }

        .forgot-password {
            a {
                @include barlowNormal;
                font-size: 13px;
                line-height: 1.1;
                color: $tertiaryColor;

                &:hover {
                    text-decoration: none;
                    color: $primaryColor;
                }
            }
        }

        .registration-password {
            a {
                @include barlowNormal;
                font-size: 13px;
                line-height: 1.1;
                color: $tertiaryColor;

                &:hover {
                    text-decoration: none;
                    color: $primaryColor;
                }
            }
        }
    }
}
</style>
