<template>
<div class="listing-page">
    <!--- top banner section -->
    <div class="banner-section">
        <v-container>
            <v-row>
                <v-col>
                    <Banner title="Objektübersicht" description="" :image="image" :banner="BannerDetail.banner" />
                </v-col>
            </v-row>
        </v-container>
    </div>
    <!--- top banner section ends -->
    <!-- listing div starts -->
    <div class="listing-wrapper">
        <v-container>
            <v-row>
                <v-col>
                    <!-- filter div starts -->
                    <div class="filter-wrapper d-flex justify-space-between align-center">
                        <div class="object-number">{{fetchingApiDetails.totalListData}} Objekte</div>

                        <div class="search-box">
                            <v-form @submit="submitSearch">
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details>
                                </v-text-field>
                            </v-form>
                        </div>

                        <div class="sorting-box d-flex align-center justify-end">
                            <div class="d-flex align-center">
                                <span>Sortierung</span>
                                <v-select v-model="sorting" :items="items" label="Bald endend" @change="changeSortingFiels" solo></v-select>
                            </div>
                        </div>
                    </div>
                    <!-- filter div ends -->
                    <!-- listing divs starts -->
                    <div class="d-flex listing-box-wrap">
                        <div v-for="item in fetchingApiDetails.fetchingApiData" :key="item.id" class="inner-listing-wrapper">
                            <Bidbox :bids="item" />
                        </div>
                        <div v-if="fetchingApiDetails.fetchinglistMessage" class="inner-listing-wrapper">
                            {{fetchingApiDetails.fetchinglistMessage}}
                        </div>
                    </div>
                    <!-- listing divs ends -->

                    <div v-if="fetchingApiDetails.totalListData" class="pagination-bottom">
                        <v-pagination @input="next" v-model="page" :length="!!Number.isInteger(fetchingApiDetails.totalListData/6) ?fetchingApiDetails.totalListData/6:(fetchingApiDetails.totalListData/6)+1" class="pagination-ul"></v-pagination>
                    </div>

                </v-col>
            </v-row>
        </v-container>
    </div>
    <!-- listing div ends -->
</div>
</template>

<script>
import Banner from "../components/banner";
import Bidbox from "../components/bid-box";
import Cookie from "js-cookie";
import ApiBaseUrl from "../utilits/commonFunction"
export default {
    name: "Listing",

    components: {
        Banner,
        Bidbox,
    },
    data: () => ({
        image: require("../assets/images/listing-background.jpeg"),
        items: ["Bald endend"],
        page: 1,
        search: "",
        sorting: "Bald endend",
        fetchingApiDetails: {
            fetchingApiData: [],
            totalListData: "",
            fetchinglistMessage: ""
        },
        BannerDetail: {
            banner: [],
            bannerIsLoading: ""
        }
    }),
    methods: {
        next(page) {
            if (this.fetchingApiDetails.totalListData > 6) {
                this.page = page
                this.fetchListing()
            }
        },
        changeSortingFiels() {
            this.page = 1
            this.fetchListing()
        },
        submitSearch(e) {
            e.preventDefault()
            if (!!this.search) {
                this.page = 1
                this.fetchListing()
            }
        },
        fetchListing() {
              let profileData = Cookie.get('profile_data') ? JSON.parse(Cookie.get('profile_data')) : ""
            this.fetchingApiDetails.fetchingApiData = ""
            let bodyParameter = {
                searchText: this.search,
                sortingText: this.sorting,
                page: this.page,
                limit: 6,
                user_id:!!profileData.id ?profileData.id:"",
                user_role: !!profileData ? profileData.role:""
            }
            fetch(ApiBaseUrl.ApiBaseUrl + 'object/list', {
                    "method": "post",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + Cookie.get('token'),
                        'Access-Control-Allow-Origin': "*"
                    },
                    body: JSON.stringify(bodyParameter),
                })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                })
                .then(response => {

                    if (response.status == 200) {
                        this.fetchingApiDetails.totalListData = response.total_count
                        this.fetchingApiDetails.fetchingApiData = response.data
                        this.fetchingApiDetails.fetchinglistMessage = !response.data.length ? "Keine Suchergebnisse gefunden." : ""
                    }
                })
                .catch(error => {

                })
        },
        bannerDetail() {
            this.BannerDetail.bannerIsLoading = true
            let bodyParameter = {
                active: true
            }
            fetch(ApiBaseUrl.ApiBaseUrl + 'home/banner', {
                    "method": "post",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + Cookie.get('token'),
                        'Access-Control-Allow-Origin': "*"
                    },
                    body: JSON.stringify(bodyParameter),
                })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                })
                .then(response => {

                    if (response.status == 200) {

                        this.BannerDetail.bannerIsLoading = false
                        this.BannerDetail.banner = response.data
                    }
                })
                .catch(error => {
                    this.BannerDetail.bannerIsLoading = false

                })
        },
    },
    mounted() {
        this.fetchListing();
        this.bannerDetail();
    },
    beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.listing-wrapper {
    margin: 5% 0;

    .filter-wrapper {
        border-bottom: 1px solid #efefee;
        margin-bottom: 30px;
        padding-bottom: 18px;

        @media (max-width: 1199px) {
            flex-wrap: wrap;
        }

        .object-number {
            @include JudsonNormal;
            font-size: 30px;
            line-height: 1.1;
            color: $primaryColor;
            width: 20%;

            @media (max-width: 1199px) {
                width: 100%;
                text-align: center;
                margin-bottom: 30px;
            }

            @media (max-width: 460px) {
                margin-bottom: 10px;
            }
        }

        .search-box {
            width: 40%;

            @media (max-width: 1199px) {
                width: 45%;
            }

            @media (max-width: 600px) {
                width: 40%;
            }

            @media (max-width: 460px) {
                width: 70%;
                margin: 10px auto;
            }

            .v-input__slot {
                @media (max-width: 991px) {
                    width: 80%;
                }
            }
        }

        .sorting-box {
            width: 40%;

            @media (max-width: 1199px) {
                width: 45%;
            }

            @media (max-width: 460px) {
                width: 100%;
                justify-content: center !important;
                padding-top: 20px;
            }

            span {
                padding-right: 30px;

                @media (max-width: 1280px) {
                    padding-right: 10px;
                }

                @media (max-width: 991px) {
                    font-size: 16px;
                }
            }
        }

        .sorting-box.d-flex {
            @include JudsonBold;
            font-size: 20px;
            line-height: 23px;
            color: $secondaryColor;
        }
    }

    .listing-box-wrap {
        flex-wrap: wrap;
        margin: 0 -10px;

        .inner-listing-wrapper {
            width: 33.3%;

            @media (max-width: 1199px) {
                width: 50%;
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }
    }

    .pagination-bottom {
        @include dFlex;
        justify-content: flex-end;
        margin: 35px 0 0;

        @media (max-width: 991px) {
            justify-content: center;
        }
    }
}
</style>
