<template>
<div>
    <!--- top banner section -->
    <div class="banner-section">
        <v-container>
            <v-row>
                <v-col>
                    <Banner title="Limbeck Bieterportal" description="Das Bieterportal für Ackerflächen, Grünland, Resthöfe und mehr" :image="image" :banner="BannerDetail.banners" />
                </v-col>
            </v-row>
        </v-container>
    </div>
    <!--- top banner section ends -->
    <!-- bidding section -->
    <div class="bidding-section">
        <v-container>
            <v-row class="align-center">
                <v-col xs="12" sm="12" md="4">
                    <div class="content-section">
                        <div>
                            <div class="content-wrap">
                                <h2>Aktuelle Neuzugänge</h2>
                                <p>
                                    Unsere neuesten Flächen auf einen
                                    Blick.
                                </p>
                            </div>
                            <v-btn @click="bidListingClick()" color="primary" dark class="green-btn" title="Weitere Neuzugänge">
                                Weitere Neuzugänge
                            </v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col xs="12" sm="12" md="8"> </v-col>
            </v-row>
        </v-container>
        <div class="slider-right">
            <v-row class="align-center">
                <v-col xs="12" sm="12" md="8" offset-md="4">
                    <div class="right-slider">
                        <BidsSlider :latestBids="latestBidSliderDetail" />
                    </div>
                    <v-btn color="primary" dark class="green-btn" title="Weitere Neuzugänge">
                        Weitere Neuzugänge
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
    <!-- bidding section ends -->
    <!-- online bid -->
    <div class="online-bid-section">
        <v-container>
            <v-row>
                <v-col>
                    <div v-if="image1" class="online-bid-wrapper" :style="{ 'background-image': 'url(' + image1 + ')' }">
                        <h2>Online bieten <span>von überall und zu jeder Zeit</span></h2>
                        <p>
                            Melden Sie sich innerhalb weniger Schritte an und bieten Sie ganz
                            bequem von zu Hause aus auf einzelne Objekte.
                        </p>
                        <v-btn @click="openRestration()" color="primary" dark class="green-btn" title="Jetzt kostenlos registrieren">
                            Jetzt kostenlos registrieren
                        </v-btn>
                        <div class="laptop-image">
                            <v-img :src="require('../assets/images/laptop-image.png')" class="" alt="Online bieten" title="Online bieten" />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <!-- online bid ends-->
    <!-- last minute aution starts -->
    <div v-if="lastDayData.length>0" class="bidding-section last-minute-auction">
        <v-container>
            <v-row class="align-center">
                <v-col xs="12" sm="12" md="4">
                    <div class="content-section">
                        <div>
                            <div class="content-wrap">
                                <h2>Last-Minute-Auktionen</h2>
                                <p>
                                    Nur noch wenige Minuten verfügbar! Die letzte Möglichkeit
                                    für ein Gebot.
                                </p>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col xs="12" sm="12" md="8"> </v-col>
            </v-row>
        </v-container>
        <div class="slider-right">
            <v-row class="align-center">
                <v-col xs="12" sm="12" md="8" offset-md="4">
                    <div class="right-slider">
                        <FiveMinBidsslider :latestBids="lastDayData" />
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
    <!-- last minute aution ends -->
</div>
</template>

<script>
import axios from 'axios';
import Banner from "../components/banner";
import BidsSlider from "../components/bids-slider";
import FiveMinBidsslider from "../components/fivemin-bids-slider.vue";
import Cookie from "js-cookie";
import ApiBaseUrl from "../utilits/commonFunction"

export default {
    name: "Home",

    components: {
        Banner,
        BidsSlider,
        FiveMinBidsslider,
    },

    data: () => ({
        image: require("../assets/images/listing-background.jpeg"),
        image1: require("../assets/images/online-bidding-image.png"),
        profileData : Cookie.get('profile_data') ? JSON.parse(Cookie.get('profile_data')) : "",
        BannerDetail: {
            banners: [],
            bannerIsLoading: ""
        },
        latestBidSliderDetail: {
            latestBidSlider: [],
            bidIsLoading: ""
        },
        lastDayData: []
    }),
    created() {},
    beforeDestroy() {},
    mounted() {
        this.bannerDetail();
        this.latestBiddDetails();
        this.last24HoursListing()
    },
    methods: {
        openRestration() {
            this.$router.push("registration")
        },
        bannerDetail() {
            this.BannerDetail.bannerIsLoading = true
            let bodyParameter = {
                active: true
            }
            fetch(ApiBaseUrl.ApiBaseUrl + 'home/banner', {
                    "method": "post",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + Cookie.get('token'),
                        'Access-Control-Allow-Origin': "*"
                    },
                    body: JSON.stringify(bodyParameter),
                })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                })
                .then(response => {
                    if (response.status == 200) {
                        this.BannerDetail.bannerIsLoading = false
                        this.BannerDetail.banners = response.data
                    }
                })
                .catch(error => {
                    this.BannerDetail.bannerIsLoading = false
                })
        },
        latestBiddDetails() {
            this.latestBidSliderDetail.bidIsLoading = true
              let lastestBody = {
                user_id: !!this.profileData.id ?this.profileData.id:"",
                user_role: !!this.profileData ?this.profileData.role:""
            }
            fetch(ApiBaseUrl.ApiBaseUrl + 'bid/details', {
                    "method": "post",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + Cookie.get('token'),
                        'Access-Control-Allow-Origin': "*"
                    },
                     body: JSON.stringify(lastestBody),
                })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                })
                .then(response => {

                    if (response.status == 200) {
                        this.latestBidSliderDetail.bidIsLoading = false
                        this.latestBidSliderDetail.latestBidSlider = response.data

                        let datass = {
                            latestSixBid: response.data
                        }
                        this.$store.dispatch('setLatestBid', datass)
                    }
                })
                .catch(error => {
                    this.latestBidSliderDetail.bidIsLoading = false

                })
        },
        bidListingClick() {
            let childnode = document.getElementsByClassName("left-menu")[0].children[0]
            childnode.children[0].classList.remove("active");
            childnode.children[1].classList.add("active");
            this.$router.push("listing")
        },
        last24HoursListing() {
              let body = {
                user_id: !!this.profileData.id ?this.profileData.id:"",
                user_role: !!this.profileData ?this.profileData.role:""
            }
            fetch(ApiBaseUrl.ApiBaseUrl + 'lastday/bid/overview', {
                    "method": "post",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + Cookie.get('token'),
                        'Access-Control-Allow-Origin': "*"
                    },
                     body: JSON.stringify(body),
                })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                })
                .then(response => {

                    if (response.status == 200) {
                        this.lastDayData = response.data
                    }
                })
                .catch(error => {

                })
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.bidding-section {
    margin: 7% 0;
    position: relative;

    @media (max-width: 991px) {
        margin: 15% 0;
    }

    div[class*="col"] {
        flex-basis: auto;
    }

    .content-section {
        .content-wrap {
            h2 {
                @include JudsonBold;
                font-size: 32px;
                line-height: 1.1;
                color: $secondaryColor;
                margin-bottom: 17px;
                max-width: 250px;

                @media (max-width: 991px) {
                    max-width: 100%;
                    margin-bottom: 10px;
                }
            }

            p {
                @include barlowNormal;
                font-size: 16px;
                line-height: 19px;
                color: $secondaryColor;
                max-width: 250px;

                @media (max-width: 991px) {
                    max-width: 100%;
                    margin-bottom: 0px;
                }
            }

            margin-bottom: 70px;
            position: relative;
            z-index: 1;

            @media (max-width: 991px) {
                margin-bottom: 0px;
            }
        }

        .green-btn {
            position: relative;
            z-index: 1;

            @media (max-width: 991px) {
                display: none;
            }
        }
    }
}

.slider-right {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0px;

    @media (max-width: 959px) {
        position: inherit;
        top: auto;
        right: auto;
    }

    div[class*="col"] {
        padding-right: 0px;

        @media (max-width: 767px) {
            padding-right: 12px;
        }
    }

    .green-btn {
        margin-top: 25px;
        margin-left: 12px;
        margin-right: 12px;

        @media (min-width: 992px) {
            display: none;
        }

        @media (max-width: 767px) {
            margin: 25px auto 0;
            display: block;
        }
    }
}

.content-section {
    min-height: 400px;
    @include flexVerticallyCenter;

    @media (max-width: 1263px) {
        min-height: 340px;
    }

    @media (max-width: 1199px) {
        min-height: 420px;
    }

    @media (max-width: 959px) {
        min-height: auto;
    }
}

.right-slider {
    &:after {
        content: "";
        position: absolute;
        width: 190px;
        height: 100%;
        background: linear-gradient(270deg,
                #ffffff 0%,
                rgba(255, 255, 255, 0) 100%);
        right: -60px;
        top: 0;

        @media (max-width: 1365px) {
            width: 100px;
        }

        @media (max-width: 1100px) {
            width: 125px;
        }

        @media (max-width: 991px) {
            width: 220px;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    padding-left: 50px;

    @media (min-width: 1904px) {
        padding-left: 0px;
    }

    @media (max-width: 1600px) {
        padding-left: 0px;
    }

    @media (max-width: 1263px) {
        padding-left: 20px;
    }

    @media (max-width: 1100px) {
        padding-left: 0px;
    }
}

.bidbox-wrapper {
    h2 {
        a {
            text-decoration: none;
            transition: 0.5s ease-in-out;

            &:hover {
                color: #000;
            }
        }
    }
}

.online-bid-wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 10px 40px 90px;
    background-position: bottom center;
    border-radius: 10px;
    width: 90%;
    position: relative;

    @media (max-width: 1263px) {
        padding: 20px 10px 30px 24px;
    }

    @media (max-width: 991px) {
        width: 100%;
        padding: 40px 10px 30px 44px;
    }

    @media (max-width: 767px) {
        padding: 25px 10px;
        @include flexHoriontallyCenter;
        flex-wrap: wrap;
        text-align: center;
    }

    h2 {
        @include JudsonBold;
        font-size: 42px;
        line-height: 1;
        color: #fff;
        margin-bottom: 20px;

        @media (max-width: 767px) {
            order: 2;
        }

        span {
            display: block;
            font-size: 32px;
            line-height: 1;
        }
    }

    p {
        @include barlowNormal;
        font-size: 16px;
        line-height: 1.1;
        color: #ffffff;
        margin-bottom: 30px;
        max-width: 420px;

        @media (max-width: 767px) {
            order: 3;
            width: 100%;
        }
    }

    .green-btn {
        margin-left: 30px;

        @media (max-width: 767px) {
            margin-left: 0px;
            order: 4;
        }
    }
}

.online-bid-section {
    margin: 10% 0;

    @media (max-width: 959px) {
        margin-right: -30px;
    }

    @media (max-width: 767px) {
        padding-top: 10%;
        margin-right: 0px;
    }
}

.laptop-image {
    position: absolute;
    max-width: 770px;
    right: -22%;
    top: -71px;

    @media (min-width: 1904px) {
        right: -18%;
    }

    @media (max-width: 1280px) {
        max-width: 700px;
        right: -15%;
        top: -50px;
    }

    @media (max-width: 1263px) {
        max-width: 670px;
        right: -30%;
        top: -60px;
    }

    @media (max-width: 1100px) {
        max-width: 600px;
        right: -20%;
        top: -36px;
    }

    @media (max-width: 991px) {
        max-width: 430px;
        right: -36px;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 8px;
    }

    @media (max-width: 767px) {
        transform: none;
        position: inherit;
        order: 1;
        right: auto;
        top: auto;
        margin-top: -60px;
        max-width: 100%;
        margin-bottom: 30px;
    }
}
</style>
