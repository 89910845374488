<template>
<div>
    <v-container>
        <div class="registration-wrapper">
            <div class="stepper-wrapper">
                <v-stepper v-model="e1" :rules="[value => !!this.$refs.register.validate()]">
                    <v-stepper-header>
                        <!-- :rules="[value => !! this.$refs.register.validate()]" -->
                        <v-stepper-step :complete="e1 > 1" step="1" editable>
                            Persönliche Daten
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 2" step="2">
                            Registrierung abschließen
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <div class="form-fileds-div">
                                <v-form @submit.prevent="secondStep" ref="register">
                                    <div class="d-flex justify-space-between">
                                        <div class="half-width">
                                            <v-select :items="items" v-model="title" :rules="titleRule" label="Anrede" color="red" outlined class="form-selectbox"></v-select>
                                            <!-- <v-text-field label="Anrede" v-model="title" outlined color="red"></v-text-field> -->
                                        </div>
                                          <div class="half-width">
                                            <v-select :items="salutationItems" v-model="salutation" label="Titel" color="red" outlined class="form-selectbox"></v-select>
                                            <!-- <v-text-field label="Titel" v-model="title" outlined color="red"></v-text-field> -->
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="half-width">
                                            <v-text-field label="Vorname" v-model="firstName" :rules="VornameRule" color="red" outlined></v-text-field>
                                        </div>
                                        <div class="half-width">
                                            <v-text-field label="Nachname" v-model="lastName" :rules="NachnameRule" color="red" outlined></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="half-width">
                                            <v-menu color="red" outlined v-model="openDatePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="dateOfBirth" :rules="GeburtsdatumRule" label="Geburtsdatum (DD-MM-JJJJ)" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="dateOfBirth" @input="openDatePicker = false"></v-date-picker>
                                            </v-menu>

                                            <!-- <v-text-field label="Geburtsdatum (DD.MM.JJJJ)" v-model="dateOfBirth" :rules="!!dateOfBirth ?GeburtsdatumRule:true" color="red" outlined></v-text-field> -->
                                        </div>
                                        <div class="half-width">
                                            <v-text-field label="Ggf. Geburtsname" v-model="maidenName" :rules="mideNameRule" color="red" outlined></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between firma-field">
                                        <div class="full-width">
                                            <v-text-field label="Firma" v-model="company" :rules="FirmaRule" color="red" outlined></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="half-width">
                                            <v-text-field label="Straße" color="red" v-model="street" :rules="StraßeRule" outlined></v-text-field>
                                        </div>
                                        <div class="half-width">
                                            <v-text-field label="Hausnummer" v-model="houseNo" :rules="HausnummerRule" color="red" outlined></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="half-width">
                                            <v-text-field label="PLZ" color="red" v-model="postCode" :rules="PLZRule" outlined></v-text-field>
                                        </div>
                                        <div class="half-width">
                                            <v-text-field label="Ort" v-model="location" :rules="OrtRule" color="red" outlined></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="half-width">
                                            <v-text-field label="Bundesland" color="red" v-model="state" :rules="BundeslandRule" outlined></v-text-field>
                                        </div>
                                        <div class="half-width">
                                            <v-text-field label="Land" v-model="country" :rules="LandRule" color="red" outlined></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="half-width">
                                            <v-text-field label="Website" v-model="Website" :rules="!!Website?WebsiteRule: true" color="red" outlined></v-text-field>
                                        </div>
                                        <div class="half-width">
                                            <v-text-field label="Telefonnummer" v-model="phone" :rules="TelefonnummerRule" color="red" outlined></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="half-width">
                                            <v-text-field label="E-Mail-Adresse" v-model="emailAddress" :rules="emailRule" color="red" outlined></v-text-field>
                                        </div>
                                        <div class="half-width">
                                            <v-text-field label="E-Mail-Adresse wiederholen" v-model="repeatEmail" :rules="confirmEmailRule" color="red" outlined></v-text-field>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div class="half-width">
                                            <v-text-field type="password" label="Passwort" v-model="password" :rules="passwordRule" color="red" outlined></v-text-field>
                                        </div>
                                        <div class="half-width">
                                            <v-text-field type="password" label="Passwort bestätigen" v-model="confirmPassword" :rules="confirmPasswordRule" color="red" outlined></v-text-field>
                                        </div>
                                    </div>
                                    <div class="stepper-btn text-center">
                                        <v-btn type="submit" color="primary" class="green-btn">Nächster Schritt
                                        </v-btn>
                                    </div>
                                </v-form>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <div class="last-step-wrapper form-fileds-div">
                                <h6>Persönliche Daten</h6>
                                <div class="d-flex justify-space-between">
                                    <div class="half-width">
                                        <div class="info-div">
                                            {{ registerDataObj.firstName}} {{ registerDataObj.lastName}}<br />
                                            {{ registerDataObj.street}} {{ registerDataObj.number}}<br />
                                            {{ registerDataObj.state}} - {{ registerDataObj.country}}
                                        </div>
                                    </div>
                                    <div class="half-width">
                                        <div class="info-div">
                                            <strong> Kontaktdaten</strong><br />
                                            {{registerDataObj.phone}}<br />
                                            {{registerDataObj.email}}<br />
                                            {{registerDataObj.website}}
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="d-flex justify-space-between liquid-div">
                                    <div class="left-content">Liquiditätsnachweis 1</div>
                                    <div class="right-content">
                                        <img src="../assets/images/wierd-icon.png" alt="wird geprüft" title="wird geprüft" />
                                        <span>wird geprüft</span>
                                    </div>
                                </div> -->
                                <div class="agb-wrapper">
                                    <h6>AGB</h6>
                                    <div class="terms-and-conditions">
                                        <div class="terms-scroller">
                                            <p>Durch die Registrierung in diesem Bieterportal werden durch den Teilnehmer
                                            folgende Bedingungen akzeptiert:</p>

                                            <p><strong>Veranstalter und Betreuer des Bieterverfahrens ist die Firma Limbeck
                                            Immobilien, Am Emspfad 14, 49716 Meppen. </strong></p>

                                            <p>Unbeschränkt geschäftsfähige, natürliche und juristische Personen sind zur
                                            Teilnahme berechtigt.
                                            Für die Registrierung sind die persönlichen Daten wie o. a. erforderlich. Ändern
                                            sich nach Registrierung die angegebenen Daten, ist der Bieter verpflichtet, die
                                            Angaben unverzüglich zu aktualisieren. Bieter können vom Veranstalter z. B.
                                            wegen nicht korrekter Angaben von der Versteigerung ausgeschlossen werden.</p>

                                            <p>Der Bieter erkennt an, ausreichende Informationen über den
                                            Auktionsgegenstand erhalten zu haben. Maßgeblich ist die Beschreibung der
                                            angebotenen Immobilie und die ggfs. durchgeführte Besichtigung. Der Käufer
                                            bestätigt, sich das Verkaufsobjekt genau angesehen zu haben.</p>
                                            
                                            <p>Das Bieterverfahren beginnt mit einem Mindestgebot zu einem festgesetzten
                                            Zeitpunkt. Die Erhöhung des Gebotes hat mindestens in, vom Mindestgebot
                                            abhängigen Steigerungsschritten zu erfolgen. Der nächst höhere
                                            Steigerungsschritt wird automatisch angezeigt. Unabhängig davon kann auch ein
                                            höheres Angebot abgegeben werden. Werden kurz vor Abschluss des
                                            Verfahrens Gebote abgegeben, verlängert sich die Auktion in kurzen
                                            Zeitintervallen, bis ein Höchstbietender feststeht. Wirksam abgegebene Gebote
                                            sind bindend. Der Bieter, der am Ende der Versteigerung das höchste Gebot
                                            abgegeben hat, erhält den Zuschlag vorbehaltlich der Zustimmung durch den
                                            Verkäufer. Das Exposé wurde entsprechend den Angaben des Verkäufers
                                            erstellt. Irrtum und Zwischenverkauf sind vorbehalten.</p>
                                            
                                            <p>Nach Abschluss des Verfahrens wird der Veranstalter, die zur Erstellung des
                                            Vertrages erforderlichen Eckdaten zusammenstellen und zur Überprüfung an die
                                            Beteiligten versenden.

                                            <p>Bei Abschluss eines Kaufvertrages ist an die Fa. Limbeck Immobilien eine
                                            Erwerberprovision in Höhe von ................ + gesetzliche MwSt. vom Käufer zu
                                            zahlen. Die Provision errechnet sich aus dem beurkundeten Kaufpreis.</p>

                                            <!-- <p><Hier Formulierung von RA Diekmann bzgl. Vertragsstrafe einfügen&gt;
                                            Salvatorische Klausel></p> -->

                                            <p>Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder werden,
                                            bleibt der Vertrag im Übrigen wirksam. Die Parteien verpflichten sich, die
                                            unwirksame Bestimmung durch eine solche zu ersetzen, die der unwirksamen
                                            nach Sinn und Zweck des Vertrages am nächsten kommt.</p>
                                        </div>
                                    </div>
                                    <v-form ref="checkValue">
                                        <div class="acceptance-box">
                                            <v-checkbox v-model="termsConditionChecked" :rules="termConditionRule" label="Ich habe die oben aufgeführten rechtlichen Informationen und Bedingungen gelesen und akzeptiere diese." value="true"></v-checkbox>
                                        </div>
                                    </v-form>
                                </div>
                                <div class="last-step-btn text-center">
                                    <v-btn color="primary" class="green-btn" @click="formSubmit()">
                                        Registrierung abschließen
                                    </v-btn>
                                </div>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </div>
        </div>
    </v-container>
</div>
</template>

<script>
import moment from 'moment'
import ApiBaseUrl from "../utilits/commonFunction"
import replceMultiStringWithSIngle from "../utilits/commonFunction"
export default {
    name: "Registration",
    data() {
        return {
            e1: 1,
            items: ['Herr', 'Frau'],
            salutationItems: ['Dr.', 'Dr. Dr.', 'Prof.', 'Prof. Dr.'],
            openDatePicker: false,
            registerDataObj: {
                email: "",
                password: "",
                title: "",
                salutation:"",
                firstName: "",
                lastName: "",
                maidenName: "",
                company: "",
                dateOfBirth: "",
                phone: "",
                website: "",
                street: "",
                number: "",
                zip: "",
                city: "",
                state: "",
                country: ""
            },
            title: "",
            salutation: "",
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            maidenName: "",
            emailAddress: "",
            company: "",
            street: "",
            houseNo: "",
            postCode: "",
            location: "",
            state: "",
            country: "",
            Website: "",
            phone: "",
            repeatEmail: "",
            password: "",
            confirmPassword: "",
            termsConditionChecked: "",
            titleRule: [
                // v => !!v || "Titel ist ein Pflichtfeld.",
            ],
            emailRule: [
                v => !!v || "Bitte geben Sie Ihre E-Mail-Adresse ein",
                v => /.+@.+/.test(v) || 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
            ],
            confirmEmailRule: [
                v => !!v || "E-Mail-Adresse wiederholen",
                v => this.emailAddress == v || "E-Mail-Adressen stimmen nicht überein"
            ],
            VornameRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Vorname ist ein Pflichtfeld",
            ],
            NachnameRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Nachname ist ein Pflichtfeld",
            ],
            mideNameRule:[
                //  v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Ggf. Geburtsname ist ein Pflichtfeld",
            ],
            GeburtsdatumRule: [
                v => !!v || "Geburtsdatum ist ein Pflichtfeld",
            ],
            FirmaRule: [
                // v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Firma ist ein Pflichtfeld",
            ],
            StraßeRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Straße ist ein Pflichtfeld",
            ],
            HausnummerRule: [
                v => /^[0-9\s]*$/g.test(v) || 'Hausnummer muss nummerisch sein',
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Hausnummer ist ein Pflichtfeld",
            ],
            PLZRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "PLZ is ist ein Pflichtfeld"
            ],
            OrtRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Ort ist ein Pflichtfeld",
            ],
            BundeslandRule: [
                // v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Bundesland ist ein Pflichtfeld",
            ],
            LandRule: [
                // v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Land ist ein Pflichtfeld",
            ],
            WebsiteRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Website ist ein Pflichtfeld",
                // v => /^(http|https):\/\/+[\www\d]+\.[\w]+(\/[\w\d]+)?/.test(v) || "Bitte geben Sie eine gültige Website-URL ein."
            ],
            TelefonnummerRule: [
                v => !!replceMultiStringWithSIngle.replceMultiStringWithSIngle(v) || "Bitte geben Sie Ihre Telefonnummer ein.",
                v => /^[0-9\s- +]*$/g.test(v) || 'Telefonnummer muss nummerisch sein'
            ],
            termConditionRule: [
                v => !!v || "Bitte akzeptieren Sie die obigen Bedingungen",
            ],
            passwordRule: [
                v => !!v || "Bitte geben Sie Ihr Passwort ein",
                v => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(v) || "Das Passwort muss mindestens 1 Kleinbuchstaben, 1 Großbuchstaben und eine 1 Zahl enthalten sowie mindestens 8 Zeichen lang sein."
            ],
            confirmPasswordRule: [
                v => !!v || "Passwort bestätigen",
                v => this.password == v || "Die eingegebenen Passwörter stimmen nicht überein."
            ]
        };
    },
    created() {},
    mounted() {

    },
    methods: {
        secondStep() {
            if (this.$refs.register.validate()) {
   
                this.e1 = 2
                const bodyParam={
               email :replceMultiStringWithSIngle.replceMultiStringWithSIngle(this.emailAddress),
                firstName :this.firstName,
                lastName : this.lastName,
                phone : this.phone,
                website : this.Website,
               street : this.street,
                number : this.houseNo,
               state : this.state,
                country : this.country}
                 this.registerDataObj =bodyParam
            }
            console.log(this.registerDataObj,"this.registerDataObj")
        },
        formSubmit() {
            if (this.$refs.checkValue.validate()) {
                this.registerDataObj.email = replceMultiStringWithSIngle.replceMultiStringWithSIngle(this.emailAddress);
                this.registerDataObj.password = this.password;
                this.registerDataObj.title = this.title;
                this.registerDataObj.salutation = this.salutation
                this.registerDataObj.firstName = this.firstName;
                this.registerDataObj.lastName = this.lastName;
                this.registerDataObj.maidenName = this.maidenName;
                this.registerDataObj.company = this.company;
                this.registerDataObj.dateOfBirth = moment(this.dateOfBirth).format('MM/DD/YYYY');
                this.registerDataObj.phone = this.phone;
                this.registerDataObj.website = this.Website;
                this.registerDataObj.street = this.street,
                this.registerDataObj.number = this.houseNo,
                this.registerDataObj.zip = this.postCode,
                this.registerDataObj.city = this.location,
                this.registerDataObj.state = this.state,
                this.registerDataObj.country = this.country
                fetch(ApiBaseUrl.ApiBaseUrl + "create", {
                        "method": "post",
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-token": "token-value",
                            'Access-Control-Allow-Origin': "*"
                        },
                        body: JSON.stringify(this.registerDataObj),
                    })
                    .then(response => {
                        if (response.ok) {
                            return response.json()

                        } else {
                            console.log("Server returned " + response.status + " : " + response.statusText);
                        }
                    })
                    .then(response => {
                        this.result = response.body;
                        this.responseAvailable = true;

                        if (response.status == 200) {
                            this.$router.push('/login')
                            this.$notify({
                                type: "success",
                                group: 'foo',
                                duration: 3000,
                                text: response.message
                            });
                        } else {
                            this.$notify({
                                type: "error",
                                group: 'foo',
                                duration: 3000,
                                text: response.message
                            });
                        }

                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.stepper-wrapper {
    max-width: 1000px;
    margin: 20px auto 7%;
    .v-stepper {
        box-shadow: 0px 0px 10px rgb(0 0 0 / 20%) !important;
    }
    .v-stepper__header {
        box-shadow: 0px 4px 7px -1px rgb(0 0 0 / 10%);
        @include JudsonBold;
        font-size: 20px;
        line-height: 1.1;
        color: $primaryColor;
        letter-spacing: 0.5px;
    }
    .form-fileds-div {
        overflow: hidden;
        transition: none;
        max-width: 620px;
        margin: 0 auto;
        padding: 30px 0 40px;
        @media (max-width: 600px) {
            padding: 15px 0 30px;
        }
        .d-flex {
            @media (max-width: 600px) {
                flex-wrap: wrap;
            }
        }
        .half-width {
            width: 48%;

            @media (max-width: 600px) {
                width: 100%;
            }
        }
        .full-width {
            width: 100%;
        }
        .stepper-btn {
            padding: 40px 0 0px;

            @media (max-width: 600px) {
                padding: 27px 0 0px;
            }
        }
        .firma-field {
            padding: 10px 0;
        }
    }
    .v-stepper__content {
        padding: 24px 15px 16px 15px;
    }
    .last-step-wrapper {
        max-width: 630px;
        margin: 0 auto;
        padding: 60px 10px 30px;
        @media (max-width: 600px) {
            padding: 20px 0 20px;
        }
        .half-width {
            @media (max-width: 600px) {
                padding-bottom: 35px;
            }
            &:last-child {
                @media (max-width: 600px) {
                    padding-bottom: 0px;
                }
            }
        }
        h6 {
            @include barlowNormal;
            font-size: 16px;
            line-height: 24px;
            color: $secondaryColor;
            margin-bottom: 40px;
        }
        .info-div {
            @include barlowNormal;
            font-size: 16px;
            line-height: 1.6;
            color: $secondaryColor;
        }
        .liquid-div {
            margin: 40px 0;
            border-top: 1px solid $tertiaryColor;
            border-bottom: 1px solid $tertiaryColor;
            padding: 8px 0;
            .left-content {
                @include barlowNormal;
                font-size: 16px;
                line-height: 1.1;
                color: $secondaryColor;
            }
            .right-content {
                @include barlowNormal;
                font-size: 16px;
                line-height: 1.1;
                color: $secondaryColor;
                @include flexVerticallyCenter;
                img {
                    padding-right: 10px;
                }
            }
        }
        .agb-wrapper {
            h6 {
                @include barlowBold;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: $secondaryColor;
            }
        }
        .terms-and-conditions {
            @include barlowNormal;
            font-size: 14px;
            line-height: 1.7;
            color: $secondaryColor;
            border-bottom: 1px solid $tertiaryColor;
            padding-bottom: 20px;
            letter-spacing: 0.5px;
            .terms-scroller {
                max-height: 220px;
                overflow-y: auto;
                &::-webkit-scrollbar-thumb {
                    background: #9e9d9c;
                    border-radius: 8px;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    height: 8px;
                    background-color: transparent;
                }
            }
        }
        .acceptance-box {
            margin: 40px 0;
            label {
                @include barlowNormal;
                font-size: 16px;
                line-height: 1.2;
                color: $secondaryColor;
                letter-spacing: 0.5px;
            }
        }
    }
}
.v-menu__content,
.form-selectbox {
    z-index: 9 !important;
}
</style>
