import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/scss/styles.scss";
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import VueCarousel from 'vue-carousel';
// import vueAwesomeCountdown from 'vue-awesome-countdown'
import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);

// Vue.use(vueAwesomeCountdown, 'vac')
Vue.use(VueCarousel);

Vue.use(Notifications, { velocity })
Vue.config.productionTip = false;


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.filter('two_digits', function (value) {
  if(value.toString().length <= 1)
  {
      return "0"+value.toString();
  }
  return value.toString();
});