<template>
  <v-container>
    <div v-if="!acceptCookie" id="banner-cookies" class="banner-cookies">
      Diese Website verwendet Cookies, um Ihnen die best mögliche Funktionalität zu gewährleisten.
      <span @click="RemoveCookie()" class="okay-btn">Verstanden</span>
    </div>
    <div class="footer-bar">
      <v-row class="footer-border">
        <v-col xs="12" sm="12" md="3" lg="2" class="first-column">
          <p>© Limbeck Immobilien</p>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="7" class="middle-column">
          <ul>
            <li @click="openFooter">
              <router-link to="/registration" title="Registrierung"
                >Registrierung</router-link
              >
            </li>
            <li @click="openFooter" v-if="this.$store.state.token">
              <router-link to="/Myaccount" title="Einloggen"
                >Mein Account</router-link
              >
            </li>
            <li v-else>
              <router-link to="/login" title="Einloggen">Einloggen</router-link>
            </li>
            <!--<li><a href="#" title="Hilfe">Hilfe </a></li>-->
            <li><a href="/FAQ" title="FAQ">FAQ</a></li>
          </ul>
        </v-col>
        <v-col xs="12" sm="12" md="3" lg="3" class="last-column">
          <ul>
            <li><a href="#" title="Datenschutz">Datenschutz</a></li>
            <li><a href="#" title="Impressum">Impressum</a></li>
            <li><a href="#" title="AGB">AGB </a></li>
          </ul>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
import Cookie from "js-cookie";
export default {
  name: "Footer",
  data: () => ({
    acceptCookie: !!Cookie.get("accept-cookie") ? true : false,
  }),
  methods: {
    openFooter() {
      let childnode =
        document.getElementsByClassName("left-menu")[0].children[0];
      let rightChildNode =
        document.getElementsByClassName("right-menu")[0].children[0];
      childnode.children[0].classList.remove("active");
      childnode.children[1].classList.remove("active");
      rightChildNode.children[0].classList.remove("active");
      rightChildNode.children[1].classList.remove("active");
      if (
        this.$router.history.current.path == "/Myaccount" ||
        this.$router.history.current.path == "/login"
      ) {
        rightChildNode.children[1].classList.add("active");
      }
    },
    RemoveCookie() {
      this.acceptCookie = true;
      Cookie.set("accept-cookie", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

.footer-bar {
  .footer-border {
    border-top: 1px solid $tertiaryColor;
    align-items: center;

    @media (max-width: 959px) {
      border-top: none;
    }

    ul {
      @include dFlex;
      @include listStyleNone;
      li {
        a {
          text-transform: capitalize;
          @include JudsonNormal;
          font-size: 18px;
          line-height: 1.1;
          color: $tertiaryColor;
          text-decoration: none;
          @media (max-width: 1263px) {
            font-size: 16px;
          }
          @media (max-width: 991px) {
            font-size: 18px;
          }
          @media (max-width: 991px) {
            font-size: 16px;
          }
          &:hover {
            color: $primaryColor;
          }
        }
        padding: 5px 15px;
        @media (max-width: 1263px) {
          padding: 5px 7px;
        }
        @media (max-width: 991px) {
          padding: 0px 12px;
        }
        &::after {
          content: "|";
          color: $tertiaryColor;
          margin-left: 12px;
          position: absolute;
          margin-top: 1px;
          @media (max-width: 1263px) {
            margin-left: 4px;
          }
          @media (max-width: 991px) {
            margin-left: 10px;
            margin-top: 2px;
          }
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
    p {
      margin-bottom: 0px;
      @include JudsonNormal;
      font-size: 18px;
      line-height: 1.1;
      color: $tertiaryColor;
      @media (max-width: 1263px) {
        font-size: 16px;
      }
      @media (max-width: 991px) {
        font-size: 18px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  .middle-column {
    @media (max-width: 959px) {
      max-width: 60%;
      flex: 0 0 60%;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      flex: 0 0 100%;
      padding-bottom: 0px;
    }
    ul {
      justify-content: center;
      @media (max-width: 959px) {
        justify-content: flex-start;
      }
      @media (max-width: 767px) {
        justify-content: center;
      }
      li {
        // &:nth-child(4) {
        //   padding-left: 9px;
        // }
        &:nth-child(3) {
          min-width: 70px;

          @media (max-width: 1440px) {
            min-width: 40px;
          }

          @media (max-width: 767px) {
            min-width: 56px;
          }
        }
      }
    }
  }

  .first-column {
    @media (max-width: 959px) {
      text-align: center;
      border-bottom: 1px solid $tertiaryColor;
    }
  }

  .last-column {
    @media (max-width: 959px) {
      max-width: 40%;
      flex: 0 0 40%;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  ul {
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
}
.banner-cookies {
  width: 100%;
  position: fixed;
  background-color: rgb(49 76 32 / 90%);
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 21px;
  z-index: 9;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  .okay-btn {
    background: #fff;
    color: #000;
    padding: 7px 10px;
    display: inline-block;
    border-radius: 5px;
    margin-left: 40px;
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}
</style>
